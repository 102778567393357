import React from 'react';
import './style.css';
import Logo from '../../images/logo_uvm.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import Footer from '../../Utils/Visuales/Footer'

export default function LoginPage(props){
    return(
        <div className='LoginPage'>
            <div className='LoginBox'>
                <img src={Logo} alt='Logo' />
                {props.loading ? 
                <div style={{marginTop: '30px'}}><CircularProgress size={60} /></div> :
                <>
                    <label>Usuario:</label>
                    <input className={props.userError ? 'input-error' : null} value={props.user} onChange={e => {
                        props.setUser(e.target.value.trim())
                    }} />
                    <label>Contraseña:</label>
                    <input type='password' className={props.passError ? 'input-error' : null} onChange={e => props.setPass(e.target.value)} onKeyPress={e => {
                        if(e.which === 13) props.login()
                    }} />
                    <button type='submit' onClick={props.login}>Entrar</button>
                    <p className='error-message'>{props.error}</p>
                </>
                }
            </div>
            <Footer version='0.0.1' />
        </div>
    )
}
