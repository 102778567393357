import React, { useState, useEffect, useContext } from 'react';
import './index.css';
import DataTables from '../../Utils/DataTables';
import { getRequest, postRequest } from '../../Utils/Funciones/requester';
import Select from 'react-select'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useSnackbar } from 'notistack';
import NivelesContext from '../../Utils/Providers/NivelesProvider';

export default function AlcanceCriterios (props){
    const headers = [
        {
            name:'Clave del Criterio',
            col:0
        },
        {
            name: 'Descripción del Criterio',
            col: 1
        },
        {
            name: 'Nivel',
            col: 2
        },
        {
            name: 'Cumplimiento',
            col: 3
        }
    ];
    const [data, setData] = useState(props.data);
    const [dataFinal, setDataFinal] = useState(props.data);
    const [tableLoading, setTableLoading] = useState(false);
    const [niveles, setNiveles] = useState(useContext(NivelesContext).state.levels);
    const [nivel, setNivel] = useState(props.nivel);
    const [cycle, setCycle] = useState(props.ciclo);
    const [cycles, setCycles] = useState([
        {
            value: '01',
            label: '01'
        },
        {
            value: '02',
            label: '02'
        },
        {
            value: '03',
            label: '03'
        }
    ]);
    const [weeks, setWeeks] = useState([])
    const [week, setWeek] = useState(props.semana)
    const [docente, setDocente] = useState(props.docente)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [reRender, setReRender] = useState(props.firstTime ? true : false)

    useEffect(() => {
        if(props.firstTime){
            if(niveles.length > 0){
                setNivel(niveles[0]);
                props.setNivel(niveles[0])
            } else {
                setData([]);
                setDataFinal([]);
                props.changeIndicadores(0, '-', '-');
                props.loadingIndicadores(false);
                setTableLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        if(nivel !== ''){
            let nuevasSemanas = []
            for(let i = 0; i < nivel.semanas; i++){
                nuevasSemanas.push({
                    value: i+1,
                    label: i+1
                })
            }
            setWeeks(nuevasSemanas);
            if(reRender){
                setWeek(nuevasSemanas[0]);
                props.setSemana(nuevasSemanas[0])
            }
        }
    }, [nivel])

    async function fetchData(){
        try {
            if(nivel !== ''){
                setTableLoading(true)
                props.loadingIndicadores(true)
                let json = {
                    semana: week.value,
                    nivel: nivel.value,
                    ciclo: cycle.value
                }
                if(docente.value!=="todos"){
                    json.id_docente=docente.value
                }
                let response = await postRequest('/reporte/alcance/criterio', json)
                if(response.s === 'ok' || response.s === 'OK'){
                    let newData = []
                    let totalCriterios = 0
                    let alcanceMayor = []
                    let alcanceMenor = []
                    if(response.d){
                        alcanceMayor = [0, response.d.resultado[0].descripcion.slice(0,20) + '...']
                        alcanceMenor = [100, response.d.resultado[0].descripcion.slice(0,20) + '...']
                        totalCriterios = response.d.resultado.length
                        response.d.resultado.forEach(criterio => {
                            if(criterio.porcentaje_alcance==="N/A"){
                                newData.push([criterio.clave,criterio.descripcion, criterio.nivel, `NA`])
                            }else{
                                newData.push([criterio.clave,criterio.descripcion, criterio.nivel, `${criterio.porcentaje_alcance}%`])
                            }
                            if(parseFloat(criterio.porcentaje_alcance) > alcanceMayor[0]){
                                alcanceMayor = [parseFloat(criterio.porcentaje_alcance), criterio.descripcion.slice(0,20) + '...',criterio.clave]
                            }
                            if(parseFloat(criterio.porcentaje_alcance) < alcanceMenor[0]){
                                alcanceMenor = [parseFloat(criterio.porcentaje_alcance), criterio.descripcion.slice(0,20) + '...',criterio.clave]
                            }
                        })
                    }
                    let comparativaComp = <div className='contenedor-comparativa'>
                        <p className='contenedor-materia materia-left'>Clave Criterio:{alcanceMayor[2]}</p>
                        <span>{alcanceMayor[0]}%</span>
                        <ArrowUpwardIcon />
                        <div>/</div>
                        <ArrowDownwardIcon />
                        <span>{alcanceMenor[0]}%</span>
                        <p className='contenedor-materia materia-right'>Clave Criterio:{alcanceMenor[2]}</p>
                    </div>
                    setData(newData)
                    setDataFinal(newData)
                    setTableLoading(false)
                    props.setData(newData)
                    if(newData.length > 0){
                        props.changeIndicadores(totalCriterios, comparativaComp, response.d.indicador.promedio === 'NaN' ? '0%' : `${Math.round(response.d.indicador.promedio * 10)/10}%`)
                    } else {
                        console.log('paso')
                        props.changeIndicadores(0, '-', '-')
                    }
                    props.loadingIndicadores(false)
                } else {
                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            }
        } catch (error){

        }
    }

    useEffect(() => {
        if(!props.firstTime){
            if(reRender){
                fetchData()
            } else {
                setReRender(true)
            }
        } else {
            props.setFirstTime(false)
        }
    }, [week, docente, cycle])

    useEffect(() => {
        function filtrar(){
            let dataNueva = []
            if(niveles.value !== 'todos'){
                dataNueva = data.filter(row => {
                    return row[1].toLowerCase().includes(niveles.value.toLowerCase())
                })
            } else {
                dataNueva = data
            }
            setDataFinal(dataNueva)
        }
        
        //filtrar()
    }, [data, niveles])

    useEffect(() => {
        if(reRender){
            let semanas = []
            for(let i = 1; i < nivel.semanas; i++){
                semanas.push({
                    value: i,
                    label: i
                })
            }
            setWeeks(semanas)
        }
    }, [nivel])

    return(
        <>
            <div className='tab-filtros'>
                <p style={{fontWeight: 700}}>Filtrar por:</p>
                <p>Ciclo:</p>
                <Select 
                    options={cycles}
                    className='select-search'
                    classNamePrefix='select-search'
                    value={cycle}
                    onChange={x => {
                        setCycle(x)
                        props.setCiclo(x)
                    }}
                />
                <p>Nivel:</p>
                <Select 
                    options={niveles}
                    className='select-search'
                    classNamePrefix='select-search'
                    value={nivel}
                    onChange={x => {
                        setNivel(x)
                        props.setNivel(x)
                    }}
                />
                <p>Semana:</p>
                <Select 
                    options={weeks}
                    className='select-weeks'
                    classNamePrefix='select-search'
                    value={week}
                    onChange={x => {
                        setWeek(x)
                        props.setSemana(x)
                    }}
                />
                <p>Docente:</p>
                <Select 
                    options={props.docentes}
                    className='select-docente-largo'
                    classNamePrefix='select-search'
                    value={docente}
                    onChange={x => {
                        setDocente(x)
                        props.setDocente(x)
                    }}
                />
            </div>
            <div style={{height: 'calc(100% - 70px)', margin: '0 20px'}}>
                <DataTables headers={headers} data={dataFinal} loading={tableLoading} centrar={[1,2,3,4]} />
            </div>
        </>
    )
}