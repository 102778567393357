import React, { useState, useEffect, useContext } from 'react';
import './index.css';
import DataTables from '../../Utils/DataTables';
import EditButton from '../../Utils/Botones/EditButton';
import { getRequest, postRequest } from '../../Utils/Funciones/requester';
import Select from 'react-select'
import { components } from 'react-select'
import LargeModal from '../../Utils/Visuales/LargeModal'
import { useHistory } from 'react-router-dom'
import ListaVerificacionContext from '../../Utils/Providers/ListaVerificacionProvider'
import ConcentradoContext from '../../Utils/Providers/ConcentradoProvider'
import { useSnackbar } from 'notistack';
import Can from '../../Utils/Funciones/can';
import NivelesContext from '../../Utils/Providers/NivelesProvider';

const Option = props => {
    return(
        <div>
            <components.Option {...props}>
                <input 
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}

const SelectContainer = ({ children, ...props }) => {
    return(
        <div style={{position: 'relative'}}>
            <components.SelectContainer {...props}>
                <div className='select-alcance-abs'>
                    Seleccione Cumplimiento...
                </div>
                {children}
            </components.SelectContainer>
        </div>
    )
}

const MultiValue = props => {
    return(
        null
    )
}

export default function AlcanceDocentes(props){
    const headers = [
        {
            name: 'Clave del Docente',
            col: 0
        },
        {
            name: 'Nombre',
            col: 1
        },
        {
            name: 'Nivel',
            col: 2
        },
        {
            name: 'Cumplimiento Prom. Docente',
            col: 3
        },
        {
            name: 'Acciones',
            col: 4
        }
    ]
    const [data, setData] = useState(props.data);
    const [dataFinal, setDataFinal] = useState(props.data);
    const [dataObjeto, setDataObjeto] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [week, setWeek] = useState(props.semana);
    const [docentes, setDocentes] = useState(props.docentes)
    const [docente, setDocente] = useState(props.docente)
    const [niveles, setNiveles] = useState(useContext(NivelesContext).state.levels);
    const [nivel, setNivel] = useState(props.nivel)
    const ciclos = [
        {
            value: '01',
            label: '01'
        },
        {
            value: '02',
            label: '02'
        },
        {
            value: '03',
            label: '03'
        }
    ]
    const [ciclo, setCiclo] = useState(props.ciclo)

    const [alcances, setAlcances] = useState(props.cumplimiento)
    const alcancesSet = [
        {
            label: 'Seleccionar todos',
            value: 'todos'
        },
        {
            label: '0% - 10%',
            value: '0-10'
        },
        {
            label: '11% - 20%',
            value: '11-20'
        },
        {
            label: '21% - 30%',
            value: '21-30'
        },
        {
            label: '31% - 40%',
            value: '31-40'
        },
        {
            label: '41% - 50%',
            value: '41-50'
        },
        {
            label: '51% - 60%',
            value: '51-60'
        },
        {
            label: '61% - 70%',
            value: '61-70'
        },
        {
            label: '71% - 80%',
            value: '71-80'
        },
        {
            label: '81% - 90%',
            value: '81-90'
        },
        {
            label: '91% - 100%',
            value: '91-100'
        }
    ]
    const [weeks, setWeeks] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [claveModal, setClaveModal] = useState('')
    const [nombreModal, setNombreModal] = useState('')
    const [dataModal, setDataModal] = useState([])
    let history = useHistory()

    const [reRender, setReRender] = useState(props.firstTime ? true : false)

    const context = useContext(ListaVerificacionContext)
    const context2 = useContext(ConcentradoContext)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if(props.firstTime){
            if(niveles.length > 0){
                if(data.length == 0){
                    setNivel(niveles[0]);
                    props.setNivel(niveles[0])
                }
            } else {
                setData([]);
                setDataFinal([]);
                setDocentes([]);
                props.loadingIndicadores(false);
                setTableLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        if(nivel !== ''){
            let nuevasSemanas = []
            for(let i = 0; i < nivel.semanas; i++){
                nuevasSemanas.push({
                    value: i+1,
                    label: i+1
                })
            }
            setWeeks(nuevasSemanas);
            if(reRender){
                setWeek(nuevasSemanas[0]);
                props.setSemana(nuevasSemanas[0])
            }
        }
    }, [nivel])

    async function fetchData(){
        setTableLoading(true)
        props.loadingIndicadores(true)
        try{
            let json = {
                semana: week.value,
                nivel: nivel.value,
                ciclo: ciclo.value
            }
            if(docente.value !== 'todos'){
                json.id_docente = docente.value
            }
            let response = await postRequest('/reporte/alcance/docente', json)
            if(response.s === 'OK'){
                setDataObjeto(response.d.resultado);
                let newData = []
                let newDataDocente = [{
                    label: 'Todos',
                    value: 'todos'
                }]
                let totalDocentes = 0
                let totalAsignaciones = 0
                let totalCumplimiento = 0
                
                if(response.d){
                    totalDocentes = response.d.resultado.length
                    response.d.resultado.forEach(docente => {
                        totalAsignaciones += docente.cursos.length
                        let newAlcance = 0
                        docente.cursos.forEach(curso => {
                            newAlcance += curso.porcentaje_alcance
                        })
                        newAlcance = newAlcance/docente.cursos.length
                        totalCumplimiento += newAlcance
                        newData.push([
                            docente.clave_docente, 
                            docente.nombre_docente, 
                            docente.nivel, 
                            `${Math.round(newAlcance * 100)/100}%`,
                            <EditButton onClick={() => openDetalleModal(docente.clave_docente, docente.nombre_docente, JSON.stringify(docente.cursos))}>Detalle</EditButton>, 
                            docente.cursos.length, 
                            newAlcance
                        ])
                        newDataDocente.push({
                            value: docente.clave_docente,
                            label: docente.nombre_docente
                        })
                    })
                }
                props.loadingIndicadores(false)
                setData(newData)
                setDataFinal(newData)
                setDocentes(newDataDocente)
                props.setDocentes(newDataDocente)
                props.setData(newData)
                setTableLoading(false)
            } else {
                enqueueSnackbar(response.m, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
            }
        } catch (error){

        }
    }

    useEffect(() => {
        if(nivel !== '' && props.firstTime){
            fetchData()
        }
    }, [])

    useEffect(() => {
        if(!props.firstTime){
            if(reRender){
               fetchData()
            } else {
                setReRender(true)
            }
        } else{
            props.setFirstTime(false)
        }
    }, [week, ciclo])

    useEffect(() => {
        if(dataFinal.length > 0){
            let totalCursos = 0;
            let totalCumplimiento = 0;
            dataFinal.forEach(row => {
                totalCursos += row[5];
                totalCumplimiento += row[6];
            })
            totalCumplimiento = `${Math.round((totalCumplimiento/dataFinal.length)*100)/100}%`;
            props.changeIndicadores(dataFinal.length, totalCursos, totalCumplimiento);
        } else {
            props.changeIndicadores(0, 0, '-')
        }
    }, [dataFinal]);

    useEffect(() => {
        let dataNueva = data.filter(row => {
            for(let i = 0; i < alcances.length; i++){
                let range = alcances[i].value.split('-')
                if(parseFloat(range[0]) <= parseFloat(row[3].split('%')[0]) && parseFloat(row[3].split('%')[0]) <= parseFloat(range[1])){
                    return true
                }
            }
            return false
        })

        if(docente.value !== 'todos'){
            dataNueva = dataNueva.filter(row => row[0] === docente.value)
        }
        setDataFinal(dataNueva)

    }, [alcances, docente])

    async function openDetalleModal(clave, nombre, cursos){
        setModalOpen(true)
        console.log('test')
        setModalLoading(true)
        setClaveModal(clave)
        setNombreModal(nombre)
        let tableData = JSON.parse(cursos)
        let dataNew = []
        if(tableData){
            dataNew = tableData.map(row => [
                row.clave,
                row.nombre,
                row.porcentaje_alcance + '%',
                <>
                    <Can I='read' a='reporte_listaverificacion'>
                        <EditButton onClick={() => {
                            context.actions.setState(ciclo, nombre, nivel, clave, row.clave)
                            history.push('/reportes/lista')
                        }}>Lista Verificación</EditButton>
                    </Can>
                    <Can I='read' a='reporte_concentrado'>
                        <EditButton onClick={() => {
                            context2.actions.setState(ciclo.value, nivel.value, nombre, clave)
                            history.push('/reportes/concentrado')
                        }} style={{marginLeft: '5px'}}>Reporte Concentrado</EditButton>
                    </Can>
                </>

            ])
        }
        setDataModal(dataNew)
        setModalLoading(false)
    }

    return(
        <>
            <div className='tab-filtros'>
                <p style={{fontWeight: 700}}>Filtrar por:</p>
                <p>Ciclo:</p>
                <Select 
                    options={ciclos}
                    className='select-search'
                    classNamePrefix='select-search'
                    value={ciclo}
                    onChange={x => {
                        setCiclo(x)
                        props.setCiclo(x)
                    }}
                />                
                <p>Nivel:</p>
                <Select 
                    options={niveles}
                    className='select-search'
                    classNamePrefix='select-search'
                    value={nivel}
                    onChange={x => {
                        setNivel(x)
                        props.setNivel(x)
                    }}
                />
                <p>Semana:</p>
                <Select
                    options={weeks}
                    className='select-weeks'
                    classNamePrefix='select-search'
                    value={week}
                    onChange={x => {
                        setWeek(x)
                        props.setSemana(x)
                    }}
                />
                <p>Docente:</p>
                <Select 
                    options={docentes}
                    className='select-docente-largo'
                    classNamePrefix='select-search'
                    value={docente}
                    onChange={x => {
                        setDocente(x)
                        props.setDocente(x)
                    }}
                />
                <p>Cumplimiento:</p>
                <Select 
                    options={alcancesSet}
                    className='select-alcance'
                    classNamePrefix='select-search'
                    components={{ Option, SelectContainer, MultiValue }}
                    isMulti
                    closeMenuOnSelect={false}
                    allowSelectAll
                    hideSelectedOptions={false}
                    value={alcances}
                    onChange={(array, action) => {
                        if(action.option.value === 'todos'){
                            if(action.action === 'deselect-option'){
                                setAlcances([])
                                props.setCumplimiento([])
                            } else {
                                setAlcances([
                                    {
                                        label: 'Seleccionar todos',
                                        value: 'todos'
                                    },
                                    {
                                        label: '0% - 10%',
                                        value: '0-10'
                                    },
                                    {
                                        label: '11% - 20%',
                                        value: '11-20'
                                    },
                                    {
                                        label: '21% - 30%',
                                        value: '21-30'
                                    },
                                    {
                                        label: '31% - 40%',
                                        value: '31-40'
                                    },
                                    {
                                        label: '41% - 50%',
                                        value: '41-50'
                                    },
                                    {
                                        label: '51% - 60%',
                                        value: '51-60'
                                    },
                                    {
                                        label: '61% - 70%',
                                        value: '61-70'
                                    },
                                    {
                                        label: '71% - 80%',
                                        value: '71-80'
                                    },
                                    {
                                        label: '81% - 90%',
                                        value: '81-90'
                                    },
                                    {
                                        label: '91% - 100%',
                                        value: '91-100'
                                    }
                                ])
                                props.setCumplimiento([
                                    {
                                        label: 'Seleccionar todos',
                                        value: 'todos'
                                    },
                                    {
                                        label: '0% - 10%',
                                        value: '0-10'
                                    },
                                    {
                                        label: '11% - 20%',
                                        value: '11-20'
                                    },
                                    {
                                        label: '21% - 30%',
                                        value: '21-30'
                                    },
                                    {
                                        label: '31% - 40%',
                                        value: '31-40'
                                    },
                                    {
                                        label: '41% - 50%',
                                        value: '41-50'
                                    },
                                    {
                                        label: '51% - 60%',
                                        value: '51-60'
                                    },
                                    {
                                        label: '61% - 70%',
                                        value: '61-70'
                                    },
                                    {
                                        label: '71% - 80%',
                                        value: '71-80'
                                    },
                                    {
                                        label: '81% - 90%',
                                        value: '81-90'
                                    },
                                    {
                                        label: '91% - 100%',
                                        value: '91-100'
                                    }
                                ])
                            }
                        } else {
                            setAlcances(array)
                            props.setCumplimiento(array)
                        }
                    }}
                />
            </div>
            <div style={{height: 'calc(100% - 70px)', margin: '0 20px'}}>
                <DataTables headers={headers} data={dataFinal.map(elem => [elem[0], elem[1], elem[2], elem[3], elem[4]])} loading={tableLoading} centrar={[3, 4, 5]} />
            </div>
            <LargeModal title='Detalle Docente' open={modalOpen} loading={modalLoading} handleClose={() => setModalOpen(false)} height={400}>
                <div className='tab-filtros inputs-modal' style={{display: 'flex'}}>
                    <p>Clave:</p>
                    <input value={claveModal} readonly />
                    <p>Nombre:</p>
                    <input value={nombreModal} readonly />
                    <p>Semana:</p>
                    <input value={week.value} readonly style={{marginRight: '20px'}} />
                </div>
                <div style={{height: 'calc(100% - 100px)'}}>
                    <DataTables 
                        data={dataModal}
                        centrar={[3,4]}
                        headers={[
                            {
                                name: 'Clave de curso',
                                col: 0
                            },
                            {
                                name: 'Asignatura',
                                col: 1
                            },
                            {
                                name: 'Cumplimiento',
                                col: 2
                            },
                            {
                                name: 'Acción',
                                col: 3
                            }
                        ]}
                    />
                </div>
            </LargeModal>
        </>
    )
}