import React, { useState, useEffect, useContext } from 'react';
import './index.css';
import DataTables from '../../Utils/DataTables';
import EditButton from '../../Utils/Botones/EditButton';
import { getRequest, postRequest } from '../../Utils/Funciones/requester';
import Select from 'react-select'
import { components } from 'react-select'
import { useHistory } from 'react-router-dom'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { useSnackbar } from 'notistack';
import moment from 'moment';
import CursoContext from '../../Utils/Providers/CursoProvider';
import SearchInput from '../../Utils/Visuales/SearchInput'
import NivelesContext from '../../Utils/Providers/NivelesProvider';

const Option = props => {
    return(
        <div>
            <components.Option {...props}>
                <input 
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}

const SelectContainer = ({ children, ...props }) => {
    return(
        <div style={{position: 'relative'}}>
            <components.SelectContainer {...props}>
                <div className='select-alcance-abs'>
                    Seleccione Cumplimeinto...
                </div>
                {children}
            </components.SelectContainer>
        </div>
    )
}

const MultiValue = props => {
    return(
        null
    )
}

export default function AlcanceAsignaciones(props) {
    const headers = [
        {
            name: 'Clave del Grupo',
            col: 0
        },
        {
            name: 'Asignatura',
            col: 1
        },
        {
            name: 'Nivel',
            col: 2
        },
        {
            name: 'Cumplimiento',
            col: 3
        },
        {
            name: 'Cumplimiento Promedio',
            col: 4
        },
        {
            name: 'Acciones',
            col: 5
        }
    ];
    const [data, setData] = useState(props.data)
    const [dataFinal, setDataFinal] = useState(props.data)
    const [tableLoading, setTableLoading] = useState(false);
    const [niveles, setNiveles] = useState(useContext(NivelesContext).state.levels);
    const [nivel, setNivel] = useState(props.nivel);
    const [alcances, setAlcances] = useState(props.cumplimiento)
    const alcancesSet = [
        {
            label: 'Seleccionar todos',
            value: 'todos'
        },
        {
            label: '0% - 10%',
            value: '0-10'
        },
        {
            label: '11% - 20%',
            value: '11-20'
        },
        {
            label: '21% - 30%',
            value: '21-30'
        },
        {
            label: '31% - 40%',
            value: '31-40'
        },
        {
            label: '41% - 50%',
            value: '41-50'
        },
        {
            label: '51% - 60%',
            value: '51-60'
        },
        {
            label: '61% - 70%',
            value: '61-70'
        },
        {
            label: '71% - 80%',
            value: '71-80'
        },
        {
            label: '81% - 90%',
            value: '81-90'
        },
        {
            label: '91% - 100%',
            value: '91-100'
        }
    ]
    const [weeks, setWeeks] = useState([])
    const [week, setWeek] = useState(props.semana)
    const [docente, setDocente] = useState(props.docente)
    let history = useHistory()
    const cycles = [
        {
            value: '01',
            label: '01'
        },
        {
            value: '02',
            label: '02'
        },
        {
            value: '03',
            label: '03'
        }
    ]
    const [cycle, setCycle] = useState(props.ciclo)
    const [reRender, setReRender] = useState(props.firstTime ? true : false)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const context = useContext(CursoContext);

    useEffect(() => {
        if(props.firstTime){
            if(niveles.length > 0){
                setNivel(niveles[0]);
                props.setNivel(niveles[0])
            } else {
                setData([]);
                setDataFinal([]);
                props.changeIndicadores(0, '-', '-');
                props.loadingIndicadores(false);
                setTableLoading(false);
            }
        }
    }, [])

    useEffect(() => {
        let nuevasSemanas = []
        for(let i = 0; i < nivel.semanas; i++){
            nuevasSemanas.push({
                value: i+1,
                label: i+1
            })
        }
        setWeeks(nuevasSemanas);
        if(reRender){
            setWeek(nuevasSemanas[0]);
            props.setSemana(nuevasSemanas[0])
        }
    }, [nivel])

    async function fetchData(){
        try {
            if(nivel !== ''){
                setTableLoading(true)
                props.loadingIndicadores(true)
                let json = {
                    semana: week.value,
                    nivel: nivel.value,
                    ciclo: cycle.value
                }
                if(docente.value!=="todos"){
                    json.id_docente=docente.value
                }
                let response = await postRequest('/reporte/alcance/materia', json)
                if(response.s === 'ok' || response.s === 'OK'){
                    let newData = []
                    let totalDocentes = 0
                    let alcanceMenor = []
                    let alcanceMayor = []
                    let alcancePromedio = 0
                    if(response.d){
                        response.d.resultado.forEach(materia => {
                            newData.push([materia.clave, materia.nombre, materia.nivel, `${materia.porcentaje_alcance}%`, `${materia.promedio_alcance}%`, <EditButton onClick={() => {
                                context.actions.setState(moment(), cycle.value, nivel.value, week.value, materia.clave_docente, materia.clave);
                                history.push('/reportes/curso');
                            }}>Detalle</EditButton>, materia.porcentaje_alcance, materia.porcentaje_alcance])
                        })
                    }
                    setData(newData)
                    setDataFinal(newData)
                    props.setData(newData)
                    setTableLoading(false)
                    props.loadingIndicadores(false)
                } else {
                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            }
        } catch(error) {

        }
    }

    useEffect(() => {
        if(!props.firstTime){
            if(reRender){
                fetchData()
            } else {
                setReRender(true)
            }
        } else {
            props.setFirstTime(false)
        }
    }, [week, niveles, docente, cycle])

    useEffect(() => {
        if(dataFinal.length > 0){
            let alcanceCumplimiento = 0;
            let alcanceMenor = [dataFinal[0][7], dataFinal[0][0]];
            let alcanceMayor = [dataFinal[0][7], dataFinal[0][0]];
            let todosMenores = 0
            let todosMayores = 0
            dataFinal.forEach(row => {
                alcanceCumplimiento += row[6];
                if(row[7] > alcanceMayor[0]){
                    todosMayores = 1
                    alcanceMayor = [row[7], row[0]];
                }else if(row[7] === alcanceMayor[0]){
                    todosMayores += 1
                }

                if(row[7] < alcanceMenor[0]){
                    todosMenores = 1
                    alcanceMenor = [row[7], row[0]];
                }else if(row[7] === alcanceMenor[0]){
                    todosMenores += 1
                }
            });
            alcanceCumplimiento = `${Math.round(((alcanceCumplimiento / dataFinal.length) * 10))/10}%`
            let comparativaComp = <div className='contenedor-comparativa'>
                <p className='contenedor-materia materia-left'>Total:{todosMayores}</p>
                <span>{Math.round(alcanceMayor[0] * 10)/10}%</span>
                <ArrowUpwardIcon />
                <div>/</div>
                <ArrowDownwardIcon />
                <span>{Math.round(alcanceMenor[0] * 10)/10}%</span>
                <p className='contenedor-materia materia-right'>Total:{todosMenores}</p>
            </div>
            props.changeIndicadores(dataFinal.length, comparativaComp, alcanceCumplimiento);
        } else {
            props.changeIndicadores(0, '-', '-')
        }
    }, [dataFinal])

    useEffect(() => {
        let dataNueva = data.filter(row => {
            for(let i = 0; i < alcances.length; i++){
                let range = alcances[i].value.split('-')
                if(parseFloat(range[0]) <= parseFloat(row[3].split('%')[0]) && parseFloat(row[3].split('%')[0]) <= parseFloat(range[1])){
                    return true
                }
            }
            return false
        })

        setDataFinal(dataNueva)
    }, [alcances, data])
/**/
    function search(text){
        
        text = String(text).toLowerCase()
        let result = data.filter(row => {
            if(row[0].toLowerCase().includes(text)) return true
            else if(row[1].toLowerCase().includes(text)) return true
            else if(row[2].toLowerCase().includes(text)) return true
            else if(row[3].toLowerCase().includes(text)) return true
            else if(row[4].toLowerCase().includes(text)) return true


            return false
        })
        setDataFinal(result)
    }


    return(
        <>
            <div className='tab-filtros'>
                <p style={{fontWeight: 700}}>Filtrar por:</p>
                <p>Ciclo:</p>
                <Select 
                    options={cycles}
                    className='select-search'
                    classNamePrefix='select-search'
                    value={cycle}
                    onChange={x => {
                        setCycle(x)
                        props.setCiclo(x)
                    }}
                />                
                <p>Nivel:</p>
                <Select 
                    options={niveles}
                    className='select-search'
                    classNamePrefix='select-search'
                    value={nivel}
                    onChange={x => {
                        setNivel(x)
                        props.setNivel(x)
                    }}
                />
                <p>Semana:</p>
                <Select 
                    options={weeks}
                    className='select-weeks'
                    classNamePrefix='select-search'
                    value={week}
                    onChange={(x) => {
                        setWeek(x)
                        props.setSemana(x)
                    }}
                />
                <p>Docente:</p>
                <Select 
                    options={props.docentes}
                    className='select-docente-largo'
                    classNamePrefix='select-search'
                    value={docente}
                    onChange={x => {
                        setDocente(x)
                        props.setDocente(x)
                    }}
                />
                <p>Cumplimiento:</p>
                <Select 
                    options={alcancesSet}
                    className='select-alcance'
                    classNamePrefix='select-search'
                    components={{ Option, SelectContainer, MultiValue }}
                    isMulti
                    closeMenuOnSelect={false}
                    allowSelectAll
                    hideSelectedOptions={false}
                    value={alcances}
                    onChange={(array, action) => {
                        if(action.option.value === 'todos'){
                            if(action.action === 'deselect-option'){
                                setAlcances([])
                                props.setCumplimiento([])
                            } else {
                                setAlcances([
                                    {
                                        label: 'Seleccionar todos',
                                        value: 'todos'
                                    },
                                    {
                                        label: '0% - 10%',
                                        value: '0-10'
                                    },
                                    {
                                        label: '11% - 20%',
                                        value: '11-20'
                                    },
                                    {
                                        label: '21% - 30%',
                                        value: '21-30'
                                    },
                                    {
                                        label: '31% - 40%',
                                        value: '31-40'
                                    },
                                    {
                                        label: '41% - 50%',
                                        value: '41-50'
                                    },
                                    {
                                        label: '51% - 60%',
                                        value: '51-60'
                                    },
                                    {
                                        label: '61% - 70%',
                                        value: '61-70'
                                    },
                                    {
                                        label: '71% - 80%',
                                        value: '71-80'
                                    },
                                    {
                                        label: '81% - 90%',
                                        value: '81-90'
                                    },
                                    {
                                        label: '91% - 100%',
                                        value: '91-100'
                                    }
                                ])
                                props.setCumplimiento([
                                    {
                                        label: 'Seleccionar todos',
                                        value: 'todos'
                                    },
                                    {
                                        label: '0% - 10%',
                                        value: '0-10'
                                    },
                                    {
                                        label: '11% - 20%',
                                        value: '11-20'
                                    },
                                    {
                                        label: '21% - 30%',
                                        value: '21-30'
                                    },
                                    {
                                        label: '31% - 40%',
                                        value: '31-40'
                                    },
                                    {
                                        label: '41% - 50%',
                                        value: '41-50'
                                    },
                                    {
                                        label: '51% - 60%',
                                        value: '51-60'
                                    },
                                    {
                                        label: '61% - 70%',
                                        value: '61-70'
                                    },
                                    {
                                        label: '71% - 80%',
                                        value: '71-80'
                                    },
                                    {
                                        label: '81% - 90%',
                                        value: '81-90'
                                    },
                                    {
                                        label: '91% - 100%',
                                        value: '91-100'
                                    }
                                ])
                            }
                        } else {
                            setAlcances(array)
                            props.setCumplimiento(array)
                        }
                    }}
                />
                <div className="search-input-desempeno-grupo">
                    <SearchInput search={search} />
                </div>
            </div>
            <div style={{height: 'calc(100% - 70px)', margin: '0 20px'}}>
                <DataTables headers={headers} data={dataFinal.map(row => [row[0], row[1], row[2], row[3], row[4], row[5]])} loading={tableLoading} centrar={[3,4,5,6]} />
            </div>
        </>
    )
}