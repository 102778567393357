import React, { useState, useEffect } from 'react';
import Contenedor from '../../Utils/Visuales/Contenedor';
import Infobox from '../../Utils/Visuales/Infobox';
import './index.css';
import DocentesDashIcon from '../../images/DocentesDashIcon';
import AsignacionesIcon from '../../images/AsignacionesIcon';
import CumplimientoIcon from '../../images/CumplimientoIcon';
import TimelineIcon from '@material-ui/icons/Timeline';
import Tabs from '../../Utils/Tabs';
import { getRequest } from '../../Utils/Funciones/requester';
import AlcanceDocentes from './AlcanceDocentes'
import AlcanceAsignaciones from './AlcanceAsignaciones'
import AlcanceCriterios from './AlcanceCriterios'
import { useSnackbar } from 'notistack';

export default function Dashboard(){
    const [tab, setTab] = useState(1)
    const [totalDocentesAsig, setTotalDocentesAsig] = useState(0)
    const [totalAsignacionesAsig, setTotalAsignacionesAsig] = useState(0)
    const [cumplimientoAsig, setCumplimientoAsig] = useState(0)

    const [totalDocentesCri, setTotalDocentesCri] = useState(0)
    const [totalAsignacionesCri, setTotalAsignacionesCri] = useState(0)
    const [cumplimientoCri, setCumplimientoCri] = useState(0)

    const [totalDocentesDoc, setTotalDocentesDoc] = useState(0)
    const [totalAsignacionesDoc, setTotalAsignacionesDoc] = useState(0)
    const [cumplimientoDoc, setCumplimientoDoc] = useState(0)

    const [loadingIndicadores, setLoadingIndicadores] = useState(false)
    const [docentes, setDocentes] = useState([])

    //Alcance Docente
    const [docenteCiclo, setDocenteCiclo] = useState({
        value: '01',
        label: '01'
    })
    const [docenteNivel, setDocenteNivel] = useState('')
    const [docenteSemana, setDocenteSemana] = useState({
        value: 1,
        label: 1
    })
    const [docenteDocente, setDocenteDocente] = useState({
        value: 'todos',
        label: 'Todos'
    })
    const [docenteCumplimiento, setDocenteCumplimiento] = useState([
        {
            label: 'Seleccionar todos',
            value: 'todos'
        },
        {
            label: '0% - 10%',
            value: '0-10'
        },
        {
            label: '11% - 20%',
            value: '11-20'
        },
        {
            label: '21% - 30%',
            value: '21-30'
        },
        {
            label: '31% - 40%',
            value: '31-40'
        },
        {
            label: '41% - 50%',
            value: '41-50'
        },
        {
            label: '51% - 60%',
            value: '51-60'
        },
        {
            label: '61% - 70%',
            value: '61-70'
        },
        {
            label: '71% - 80%',
            value: '71-80'
        },
        {
            label: '81% - 90%',
            value: '81-90'
        },
        {
            label: '91% - 100%',
            value: '91-100'
        }
    ])
    const [dataDocente, setDataDocente] = useState([])
    const [docenteFirstTime, setDocenteFirstTime] = useState(true)
    const [docenteDocentes, setDocenteDocentes] = useState([])

    //Alcance Asignaciones
    const [asigCiclo, setAsigCiclo] = useState({
        value: '01',
        label: '01'
    })
    const [asigNivel, setAsigNivel] = useState('')
    const [asigSemana, setAsigSemana] = useState({
        value: 1,
        label: 1
    })
    const [asigDocente, setAsigDocente] = useState({
        value: 'todos',
        label: 'Todos'
    })
    const [asigCumplimiento, setAsigCumplimiento] = useState([
        {
            label: 'Seleccionar todos',
            value: 'todos'
        },
        {
            label: '0% - 10%',
            value: '0-10'
        },
        {
            label: '11% - 20%',
            value: '11-20'
        },
        {
            label: '21% - 30%',
            value: '21-30'
        },
        {
            label: '31% - 40%',
            value: '31-40'
        },
        {
            label: '41% - 50%',
            value: '41-50'
        },
        {
            label: '51% - 60%',
            value: '51-60'
        },
        {
            label: '61% - 70%',
            value: '61-70'
        },
        {
            label: '71% - 80%',
            value: '71-80'
        },
        {
            label: '81% - 90%',
            value: '81-90'
        },
        {
            label: '91% - 100%',
            value: '91-100'
        }
    ])
    const [asigFirstTime, setAsigFirstTime] = useState(true)
    const [asigData, setAsigData] = useState([])

    //Alcance Criterios
    const [criCiclo, setCriCiclo] = useState({
        value: '01',
        label: '01'
    })
    const [criNivel, setCriNivel] = useState('')
    const [criSemana, setCriSemana] = useState({
        value: 1,
        label: 1
    })
    const [criDocente, setCriDocente] = useState({
        value: 'todos',
        label: 'Todos'
    })
    const [criData, setCriData] = useState([])
    const [criFirstTime, setCriFirstTime] = useState(true)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function setIndicadoresAsig(docentes, asignaciones, cumplimiento){
        setTotalDocentesAsig(docentes)
        setTotalAsignacionesAsig(asignaciones)
        setCumplimientoAsig(cumplimiento)
    }

    function setIndicadoresCri(docentes, asignaciones, cumplimiento){
        setTotalDocentesCri(docentes)
        setTotalAsignacionesCri(asignaciones)
        setCumplimientoCri(cumplimiento)
    }

    function setIndicadoresDoc(docentes, asignaciones, cumplimiento){
        setTotalDocentesDoc(docentes)
        setTotalAsignacionesDoc(asignaciones)
        setCumplimientoDoc(cumplimiento)
    }

    useEffect(() => {
        const pageController = new AbortController()
        const pageSignal = pageController.signal

        async function fetchDocentes(){
            try {
                let response = await getRequest('/docente', pageSignal)
                if(response.s === 'OK'){
                    let data = [{
                        value: 'todos',
                        label: 'Todos'
                    }];
                    response.d.forEach(docente => {
                        data.push({
                            value: docente.id,
                            label: docente.nombre
                        })
                    })
                    setDocentes(data)
                } else {
                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            } catch(error){

            }
        }

        fetchDocentes()
        return function cleanup(){
            pageController.abort()
        }
    }, [])

    return(
        <Contenedor title='Dashboard'>
            <div className='info-boxes'>
                {
                    tab === 1 ?
                    <>
                        <Infobox src='' title='Total de Docentes Activos' total={totalDocentesAsig} icon={<DocentesDashIcon />} loading={loadingIndicadores} />
                        <Infobox src='' title='Total de Grupos' total={totalAsignacionesAsig} icon={<AsignacionesIcon />} loading={loadingIndicadores} />
                        <Infobox src='' title='% Cumplimiento Promedio' total={cumplimientoAsig} icon={<CumplimientoIcon />} loading={loadingIndicadores} />
                    </> : null
                }
                {
                    tab === 2 ?
                    <>
                        <Infobox src='' title='Total de Grupos' total={totalDocentesCri} icon={<DocentesDashIcon />} loading={loadingIndicadores} />
                        <Infobox src='' title='Comparativa' total={totalAsignacionesCri} icon={<TimelineIcon />} loading={loadingIndicadores} />
                        <Infobox src='' title='% Cumplimiento' total={cumplimientoCri} icon={<CumplimientoIcon />} loading={loadingIndicadores} />
                    </> : null
                }
                {
                    tab === 3 ?
                    <>
                        <Infobox src='' title='Total de Criterios' total={totalDocentesDoc} icon={<DocentesDashIcon />} loading={loadingIndicadores} />
                        <Infobox src='' title='Comparativa' total={totalAsignacionesDoc} icon={<TimelineIcon />} loading={loadingIndicadores} />
                        <Infobox src='' title='% Cumplimiento' total={cumplimientoDoc} icon={<CumplimientoIcon />} loading={loadingIndicadores} />
                    </> : null
                }
            </div>
            <div style={{marginTop: '10px', height: 'calc(100% - 110px)'}}>
                <Tabs
                    firstTab={<AlcanceDocentes docentes={docentes} changeIndicadores={setIndicadoresAsig} loadingIndicadores={setLoadingIndicadores} ciclo={docenteCiclo} setCiclo={setDocenteCiclo} nivel={docenteNivel} setNivel={setDocenteNivel} semana={docenteSemana} setSemana={setDocenteSemana} docente={docenteDocente} setDocente={setDocenteDocente} cumplimiento={docenteCumplimiento} setCumplimiento={setDocenteCumplimiento} data={dataDocente} setData={setDataDocente} firstTime={docenteFirstTime} setFirstTime={setDocenteFirstTime} docentes={docenteDocentes} setDocentes={setDocenteDocentes} />}
                    secondTab={<AlcanceAsignaciones docentes={docentes} changeIndicadores={setIndicadoresCri} loadingIndicadores={setLoadingIndicadores} ciclo={asigCiclo} setCiclo={setAsigCiclo} nivel={asigNivel} setNivel={setAsigNivel} semana={asigSemana} setSemana={setAsigSemana} docente={asigDocente} setDocente={setAsigDocente} cumplimiento={asigCumplimiento} setCumplimiento={setAsigCumplimiento} firstTime={asigFirstTime} setFirstTime={setAsigFirstTime} data={asigData} setData={setAsigData} />}
                    thirdTab={<AlcanceCriterios docentes={docentes} changeIndicadores={setIndicadoresDoc} loadingIndicadores={setLoadingIndicadores} ciclo={criCiclo} setCiclo={setCriCiclo} nivel={criNivel} setNivel={setCriNivel} semana={criSemana} setSemana={setCriSemana} docente={criDocente} setDocente={setCriDocente} data={criData} setData={setCriData} firstTime={criFirstTime} setFirstTime={setCriFirstTime} />}
                    changeBox={setTab}
                />
            </div>
        </Contenedor>
    )
}