import React, { useState, useEffect, useContext, useRef } from 'react';
import Contenedor from '../../Utils/Visuales/Contenedor';
import SearchInput from '../../Utils/Visuales/SearchInput';
import MasivaButton from '../../Utils/Botones/MasivaButton';
import DataTable from '../../Utils/DataTables';
import { getRequest, postRequest } from '../../Utils/Funciones/requester';
import EditButton from '../../Utils/Botones/EditButton';
import { VerificacionModal } from '../../Utils/Visuales/VerificacionModal';
import RegenerarModal from '../../Utils/Visuales/RegenerarModal';
import moment from 'moment';
import 'moment/locale/es'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import './style.css';
import MailModal from '../../Utils/Visuales/MailModal';
import ListaEditable from '../../Utils/ListaEditable/ListaEditable';
import LargeModal from '../../Utils/Visuales/LargeModal';
import Grid from '@material-ui/core/Grid';
import downloadJS from 'downloadjs';
import ListaVerificacionContext from '../../Utils/Providers/ListaVerificacionProvider';
import { useSnackbar } from 'notistack';
import NivelesContext from '../../Utils/Providers/NivelesProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import BlackButton from '../../Utils/Botones/BlackButton';

export default function ReportesLista(props){
    const headers = [
        {
            name: 'Clave del Docente',
            col: 0
        },
        {
            name: 'Docente',
            col: 1
        },
        {
            name: 'Clave del Curso',
            col: 2
        },
        {
            name: 'Curso',
            col: 3
        },
        {
            name: 'Nivel',
            col: 4
        },
        {
            name: 'Acción',
            col: 5
        }
    ];
    const [dataFinal, setDataFinal] = useState([]);
    const [dataReal, setDataReal] = useState([]);
    const [dataToFilterModulo,setDataToFilterModulo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [openVerificacionModal,setOpenVerificacionModal]=useState(false);
    const [dataDownload, setDataDownload] = useState(false);
    const [editInfo,setEditInfo] = useState({})
    const [editModal,setEditModal] = useState(false)
    const [calendarHeaders, setCalendarHeaders] = useState([]);
    const [calendarWeeks, setCalendarWeeks] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const context = useContext(ListaVerificacionContext)
    const cycles = [
        {
            value: '01',
            label: '01'
        },
        {
            value: '02',
            label: '02'
        },
        {
            value: '03',
            label: '03'
        }
    ]
    const [modulos,setModulos] = useState([{value: 'Todos',label: 'Todos'}])

    const [modulo,setModulo] = useState(context.state.modulo ? context.state.modulo : {label: 'Todos', value: 'Todos'})
    const [cycle, setCycle] = useState(context.state.cycle ? context.state.cycle : {label: '01', value: '01'});
    const [year, setYear] = useState(new Date());
    const [levels, setLevels] = useState(useContext(NivelesContext).state.levels);
    const [level, setLevel] = useState(context.state.level ? context.state.level : levels[0]);
    const [docentes, setDocentes] = useState([]);
    const [docente, setDocente] = useState(context.state.docente ? {value: context.state.claveDocente, label: context.state.docente} : {
        value: 'todos',
        label: 'Todos'
    });
    const [refreshDocente, setRefreshDocente] = useState(false)
    const [urlPdf, setUrlPdf] = useState('')
    const [urlPdfLink, setUrlPdfLink] = useState('')

    const [mailModal, setMailModal] = useState(false)
    const [correoPersonal, setCorreoPersonal] = useState('')
    const [correoInstitucional, setCorreoInstitucional] = useState('')
    const [otro, setOtro] = useState('')

    const [modalRegistro, setModalRegistro] = useState(false);
    const [modalRegistroDataLoading, setModalRegistroDataLoading] = useState(false);
    const [dataRegistro, setDataRegistro] = useState([]);
    const [claveCursoRegistro, setClaveCursoRegistro] = useState('');
    const [claveDocenteRegistro, setClaveDocenteRegistro] = useState('');

    const [embedLog, setEmbedLog] = useState(false);
    const [urlLog, setUrlLog] = useState('');
    const [errorLog, setErrorLog] = useState(false);

    const [correoInstitucionalValido, setCorreoInstitucionalValido] = useState(true);
    const [correoPersonalValido, setCorreoPersonalValido] = useState(true);
    const [correoOtroValido, setCorreoOtroValido] = useState(true);
    const [idTeacherAssignment, setIdTeacherAssignment] = useState('');
    const [emailLoading, setEmailLoading] = useState(false);
    const [nombreDocente, setNombreDocente] = useState('');
    const [asunto, setAsunto] = useState('');
    const [asuntoValido, setAsuntoValido] = useState(true);
    const [cuerpo, setCuerpo] = useState('');
    const [cuerpoValido, setCuerpoValido] = useState(true);
    
    const [modalRegenerar, setModalRegenerar] = useState(false);
    const [wantWeek, setWantWeek] = useState(true);
    const [totalWeeks, setTotalWeeks] = useState([]);
    const [regenerarWeek, setRegenerarWeek] = useState('');
    const [verificandoRegeneracion, setVerificandoRegeneracion] = useState('');
    const [ultimoRegenerando, setUltimoRegenerando] = useState(false);
    const [progresoRegenerando, setProgresoRegenerando] = useState(0);
    const [functionVerificacion, setFunctionVerificacion] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [claveRegenerada, setClaveRegenerada] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [firstTime, setFirstTime] = useState(true);
    const [regresarBtn, setRegresarBtn] = useState(true);

    const [paramsRegenerar, setParamsRegenerar] = useState([])

    const [mailModalLoading, setMailModalLoading] = useState(false)
    const [ultimoLog, setUltimoLog] = useState('')
    const [semana, setSemana] = useState(0)
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFinal, setFechaFinal] = useState('')
    const [nombreCurso, setNombreCurso] = useState(false)

    const [loadingEditModal,setLoadingEditModal] = useState(false)
    const [nss_docente,setNSSDocente]=useState('')
    const [id_asignacion,setIDAsignacion]=useState('')
    const [maxWeek,setmMaxWeek] = useState(0)
    const [moduloEdit,setModuloEdit] = useState('')
    useEffect(() => {
        console.log(context)
        context.actions.setState(null, null, null, null, context.state.claveMateria)

        if(context.state.claveMateria){
            setLoadingModal(true);
            setOpenVerificacionModal(true);
        }

        fetchData();
    }, []);

    async function openModalCorreoHandle(){
        setMailModal(true)
        setMailModalLoading(true)
        const res = await fetch(ultimoLog)
        const data = await res.text()
        const strings = data.split('\n')
        let semana = strings[9][strings[9].length-2]
        let fecha_inicio = moment(strings[11].substr(63, 10)).locale('es').subtract(7, 'days').format('DD [de] MMMM [del] YYYY')
        let fecha_fin = moment(strings[11].substr(63, 10)).locale('es').subtract(1, 'days').format('DD [de] MMMM [del] YYYY')

        setCuerpo(`Buen día apreciable docente ${nombreDocente}:
        
Con el gusto de saludarle, por este medio le comparto su Lista de Verificación, correspondiente a la semana ${semana}, que comprende del ${fecha_inicio} al ${fecha_fin}, de la materia ${nombreCurso}, grupo ${claveCursoRegistro}.

Nota: Si tiene dudas de los rubros evaluados, por favor acérquese con su coordinador.

Sin más por el momento, quedo a sus órdenes.

¡Excelente fin de semana!`);
    }

    async function fetchData(){
        try {
            setLoading(true)
            let json = {
                year: parseInt(moment(year).format('YY')),
                ciclo: cycle.value,
                nivel: level.value
            }
            let response = await postRequest('/reporte/lista/verificacion', json)
            if(response.s === 'OK'){
                setFirstTime(false);
                let data = []
                let data2 = []
                let dataDocentes = [{
                    value: 'todos',
                    label: 'Todos'
                }]
                let dataModulos = [{
                    value:'Todos',
                    label:'Todos'
                }]
                let myModulosReg=[]
                if(response.d){
                    response.d.forEach(row => {
                        if(myModulosReg.includes(row.modulo)){

                        }else{
                            myModulosReg.push(row.modulo)
                            dataModulos.push({
                                value:row.modulo,
                                label:row.modulo
                            })
                        }
                        data.push([
                            row.clave_docente,
                            row.nombre_docente,
                            row.clave_curso,
                            row.nombre_curso,
                            row.nivel,
                            <EditButton id={`btnLista${row.clave_curso}`} onClick={openModalVerificacion.bind(ReportesLista, row.url_pdf, row.correo_personal, row.correo_institucional, row.clave_curso, row.clave_docente, row.id_teacher_assignment_pdf, row.nombre_docente, row.nombre_curso,row.id_curso,row.modulo)}>Ver</EditButton>,
                            row.modulo
                        ])
                        data2.push({
                            Clave: row.clave_docente,
                            Docente: row.nombre_docente,
                            Clave_Curso: row.clave_curso,
                            Curso: row.nombre_curso,
                            Nivel: row.nivel
                        })
                        dataDocentes.push({
                            value: row.clave_docente,
                            label: row.nombre_docente
                        })
                    })
                } else {
                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }

                setDataToFilterModulo(data)
                
                let result = data
                setDataReal(result)

                if(docente.value === 'todos'){
                    setDataFinal(result)

                } else {
                    let dataFilter = result.filter(row => (
                        row[0] === docente.value
                    ))
                    setDataFinal(dataFilter)
                }

                setLoading(false)
                setDataDownload(data2)
                setModulos(dataModulos)
                dataDocentes = Array.from(new Set(dataDocentes.map(s => s.value)))
                    .map(value => {
                        return {
                            value: value,
                            label: dataDocentes.find(s => s.value === value).label
                        }
                    })
                setDocentes(dataDocentes)
                if(context.state.claveMateria){
                    setTimeout(() => {
                        document.getElementById(`btnLista${context.state.claveMateria}`).click()
                        context.actions.setState(null, null, null, null, null)
                    }, 1000)
                }
                if(claveCursoRegistro && openVerificacionModal){
                    for(let j = 0; j < data.length; j++){
                        if(claveCursoRegistro === data[j][2] && claveDocenteRegistro === data[j][0]){
                            data[j][5].props.onClick()
                        }
                    }
                }
            } else {
                enqueueSnackbar(response.m, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
            }
        } catch(error){

        }
    }

    function search(text){
        text = text.toLowerCase()
        let result = dataReal.filter(row => {
            if(row[0].toLowerCase().includes(text)) return true
            else if(row[1].toLowerCase().includes(text)) return true
            else if(row[2].toLowerCase().includes(text)) return true
            else if(row[3].toLowerCase().includes(text)) return true
            else if(row[4].toLowerCase().includes(text)) return true
            return false
        })
        setDataFinal(result)
    }
    
    async function openModalVerificacion(urlPdf, correoPersonal, correoInstitucional, clave_curso, clave_docente, idPdf, nombre_docente, nombre_curso,idCurso,modulo_parametro){
        setLoadingModal(false);
        setModuloEdit(modulo_parametro)
        setUrlPdf(urlPdf);
        let tmpString = moment().format("YYYYMMDDHHmmss")
        setUrlPdfLink(`${urlPdf}?tmp=${tmpString}`);
        setCorreoPersonal(correoPersonal);
        setCorreoInstitucional(correoInstitucional);
        setClaveCursoRegistro(clave_curso);
        setClaveDocenteRegistro(clave_docente);
        setIdTeacherAssignment(idPdf);
        setNombreDocente(nombre_docente);
        setAsunto(`Lista de Verificación: ${clave_curso}`);
        setIDAsignacion(idCurso)
        setNombreCurso(nombre_curso)

    
        setModalRegistroDataLoading(true);
        setOpenVerificacionModal(true);
        let actualTime = moment().format('YYYYMMDDhhmm')
        let response = await getRequest(`/logs?clave_curso=${clave_curso}&clave_docente=${clave_docente}`)
        if(response.s === 'OK'){
            let logData = []
            response.d.map(row => {
                logData.push([
                    row.fecha_hora,
                    row.nombre_archivo,
                    <EditButton onClick={() => openLog(`${row.url}?t=${actualTime}`)}>Abrir</EditButton>
                ])
            })
            setUltimoLog(response.d[response.d.length-1].url)
            setDataRegistro(logData)
            setModalRegistroDataLoading(false);
        } else {
            setModalRegistroDataLoading(false);
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });            
            setErrorLog(response.m);
        }
    }

    function downloadFile(){
        downloadJS(urlPdf)
        fetch(urlPdf)
            .then(response => response.blob())
            .then(blob => {
                const blobURL = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = blobURL;
                a.style = "display: none";

                a.click();
            })
    }

    function downloadReport(){
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const ws = XLSX.utils.json_to_sheet(dataDownload)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data']}
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], {type: fileType})
        let today = moment().format('YYYY/MM/DD')
        FileSaver.saveAs(data, `Reporte Lista de Verificación ${today}.xlsx`)
    }

    function validateEmail(email){
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function changeCorreoInstitucional(e){
        let value = e.target.value;
        if(validateEmail(value) || value.length === 0){
            setCorreoInstitucionalValido(true);

        } else {
            setCorreoInstitucionalValido(false);
        }
        setCorreoInstitucional(value);
    }

    function changeCorreoPersonal(e){
        let value = e.target.value;
        if(validateEmail(value) || value.length === 0){
            setCorreoPersonalValido(true);
        } else {
            setCorreoPersonalValido(false);
        }
        setCorreoPersonal(value);
    }

    function changeCorreoOtro(e){
        let value = e.target.value;
        if(validateEmail(value) || value.length === 0){
            setCorreoOtroValido(true);
        } else {
            setCorreoOtroValido(false);
        }
        setOtro(value);
    }

    function changeAsunto(e){
        let value = e.target.value;
        if(value.length > 0){
            setAsuntoValido(true);
        } else {
            setAsuntoValido(false);
        }
        setAsunto(value);
    }

    function changeCuerpo(e){
        let value = e.target.value;
        if(value.length > 0){
            setCuerpoValido(true);
        } else {
            setCuerpoValido(false);
        }
        setCuerpo(value);
    }

    async function sendMail(){
        if(correoInstitucionalValido && correoPersonalValido && correoOtroValido){
            setEmailLoading(true);
            let receiversArray = []
            if(correoInstitucional){
                receiversArray.push(correoInstitucional);
            }
            if(correoPersonal){
                receiversArray.push(correoPersonal);
            }
            if(otro){
                receiversArray.push(otro);
            }

            let json = {
                subject: asunto,
                body2: cuerpo,
                receiver: receiversArray,
                url_s3_file: urlPdfLink,
                nss_docente: claveDocenteRegistro,
                tipo: 'Lista Verificacion',
                status: 'true',
                id_teacher_assignment_pdf: idTeacherAssignment
            }

            let response = await postRequest('/email/enviar', json);
            setEmailLoading(false);
            if(response.s === 'OK'){
                setMailModal(false);
                enqueueSnackbar('Correo enviado correctamente.', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
            } else {
                enqueueSnackbar(response.m, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                })
            }
        } else {
            enqueueSnackbar(`Alguno de los correos tiene un formato incorrecto.`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }
    }

    function openModalRegenerar(){
        setVerificandoRegeneracion(false);
        setUltimoRegenerando(false);
        setProgresoRegenerando(0);
        setWantWeek(true);
        let totalWeeksLevel = level.semanas;
        console.log(totalWeeksLevel)
        let totalWeeksArray = [];
        for(let i = 0; i < totalWeeksLevel; i++){
            totalWeeksArray.push({
                value: i+1,
                label: i+1
            });
        }
        setTotalWeeks(totalWeeksArray);
        setRegenerarWeek('');
        setModalRegenerar(true);
    }

    async function openModalRegistro(){
        setErrorLog(false);
        setEmbedLog(false);
        setModalRegistro(true);
        setRegresarBtn(true);
    }

    async function enviarRegeneracion(){
        setWantWeek(false);
        setVerificandoRegeneracion(true);
        let json = {
            clave_docente: claveDocenteRegistro,
            clave_curso: claveCursoRegistro,
            semana: regenerarWeek.value,
            accion: 'generar',
            id_job: '',
            id_location: ''
        };
        let response = await postRequest('/lista/verificacion/generar', json);
        if(response.s === 'OK'){
            verificarRegeneracion(response.d.id_rastreo);
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setModalRegenerar(false);
        }
    }

    async function verificarRegeneracion(id_rastreo){
        let json = {
            clave_docente: claveDocenteRegistro,
            clave_curso: claveCursoRegistro,
            semana: regenerarWeek.value,
            accion: 'verificar',
            id_job: '',
            id_location: id_rastreo
        };
        let response = await postRequest('/lista/verificacion/generar', json);
        if(response.s === 'OK'){
            if(response.d.id_job !== ''){
                regenerando(id_rastreo, response.d.id_job);
            } else {
                setTimeout(() => verificarRegeneracion(id_rastreo), 10000);
            }
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setModalRegenerar(false);
        }
    }

    async function regenerando(id_rastreo, id_job){
        setVerificandoRegeneracion(false);
        setUltimoRegenerando(true);
        let json = {
            clave_docente: claveDocenteRegistro,
            clave_curso: claveCursoRegistro,
            semana: regenerarWeek.value,
            accion: 'verificar',
            id_job: id_job,
            id_location: id_rastreo
        }
        let response = await postRequest('/lista/verificacion/generar', json);
        if(response.s === 'OK'){
            if(progresoRegenerando === 0){
                let unidadSeg = response.d.estimatedDuration/100;
                var progressInterval = setInterval(() => {
                    if(progresoRegenerando < 100){
                        setProgresoRegenerando(progresoRegenerando => progresoRegenerando + 1);
                    } else {
                        clearInterval(progressInterval);
                    }
                }, unidadSeg*1000);
            }
            if(response.d.result){
                if(response.d.result === 'SUCCESS'){
                    enqueueSnackbar('Regenerado correctamente.', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    setLoadingModal(true);
                    setModalRegenerar(false);
                    setOpenVerificacionModal(true);
                    setTimeout(() => {
                        setClaveRegenerada(true);
                        fetchData();
                    }, 1000);
                } else {
                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    setModalRegenerar(false);
                }
            } else {
                setTimeout(() => {
                    regenerando(id_rastreo, id_job);
                }, 20000);
            }
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setModalRegenerar(false);
        }
    }

    async function editarSemana(semanaAEditar){
        let criteria_results=[]
        calendarWeeks.forEach(reg=>{
            criteria_results.push({
                criteria_key:reg.clave,
                valid: reg.weeks[semanaAEditar] === "N/A" ? -1 : reg.weeks[semanaAEditar] === "X" ? 1 : 0
            })
        })

        let body={
            datos:{            
                id_level_schedule:editInfo.d.id_level_schedule,
                id_teacher_assignment:editInfo.d.id_teacher_assignment,
                assignment_key:editInfo.d.clave_asignatura,
                semana:semanaAEditar+1,
                criteria_results
            },
            accion:"generar",
            id_job:"",
            id_location:""
        }
        setWantWeek(false);
        setVerificandoRegeneracion(true);
        let response = await postRequest('/lista/verificacion/resultadomanual/generar', body);
        if(response.s === 'OK'){
            verificarRegeneracion2(response.d.id_rastreo);
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setWantWeek(true);
            setVerificandoRegeneracion(false);
        }
    }

    async function verificarRegeneracion2(id_rastreo){
        let body={
            datos:"",
            accion:"verificar",
            id_job:"",
            id_location:id_rastreo
        }
        let response = await postRequest('/lista/verificacion/resultadomanual/generar', body);
        if(response.s === 'OK'){
            if(response.d.id_job !== ''){
                regenerando2(id_rastreo, response.d.id_job);
            } else {
                setTimeout(() => verificarRegeneracion2(id_rastreo), 10000);
            }
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }
    }

    async function regenerando2(id_rastreo, id_job){
        setVerificandoRegeneracion(false);
        setUltimoRegenerando(true);
        let body={
            datos:"",
            accion:"verificar",
            id_job:id_job,
            id_location:id_rastreo
        }
        let response = await postRequest('/lista/verificacion/resultadomanual/generar', body);
        if(response.s === 'OK'){
            if(progresoRegenerando === 0){
                let unidadSeg = response.d.estimatedDuration/100;
                var progressInterval = setInterval(() => {
                    if(progresoRegenerando < 100){
                        setProgresoRegenerando(progresoRegenerando => progresoRegenerando + 1);
                    } else {
                        clearInterval(progressInterval);
                    }
                }, unidadSeg*1000);
            }
            if(response.d.result){
                if(response.d.result === 'SUCCESS'){
                    enqueueSnackbar('Regenerado correctamente.', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    setWantWeek(true);
                    setVerificandoRegeneracion(false)
                    let tmpString = moment().format("YYYYMMDDHHmmss")
                    setUrlPdfLink(response.d.url);
                    setUltimoRegenerando(false)
                    setEditModal(false)
                } else {
                    setWantWeek(true);
                    setVerificandoRegeneracion(false)
                    setUltimoRegenerando(false)
                    enqueueSnackbar(response.m+":error al generar semana", {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            } else {
                setTimeout(() => {
                    regenerando2(id_rastreo, id_job);
                }, 20000);
            }
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }
    }



    function openLog(url){
        setUrlLog(url);
        setEmbedLog(true);
        setRegresarBtn(false);
    }

    function handleChangeModulo(e){
        let text = ""
        if(e.value==="Todos"){
            text=""
        }else{
            text=e.value
        }
        text = text.toLowerCase()
        let result = dataToFilterModulo.filter(row => {
            if(row[6].toLowerCase().includes(text)) return true
            return false
        })
        setModulo(e)
        setDataFinal(result)
        setDataReal(result)
    }
    function editOpen(){
        let json = {
            year: moment(year).format('YY'),
            ciclo: cycle.value,
            nss_docente: claveDocenteRegistro,
            id_asignacion: id_asignacion,
            modulo:moduloEdit,
            procesar:false
        }
        setWantWeek(true);
        setUltimoRegenerando(false)
        setVerificandoRegeneracion(false);
        setEditModal(true)
        setLoadingEditModal(true)

        postRequest('/lista/verificacion',json)
        .then(datos=>{
            if(datos.s=="OK"){            
                let semanas = []
                let lengthSemanas = datos.d.grupo[0].encuadres[0].semanas.length
                let encuadres = []
                setmMaxWeek(datos.d.ultima_semana_calculada)
                datos.d.grupo[0].encuadres.forEach(reg=>{
                    encuadres.push({
                        clave:reg.clave,
                        name:reg.criterio,
                        weeks:reg.semanas
                    })
                })
                datos.d.grupo[1].encuadres.forEach(reg=>{
                    encuadres.push({
                        clave:reg.clave,
                        name:reg.criterio,
                        weeks:reg.semanas
                    })
                })
                datos.d.grupo[2].encuadres.forEach(reg=>{
                    encuadres.push({
                        clave:reg.clave,
                        name:reg.criterio,
                        weeks:reg.semanas
                    })
                })
                datos.d.grupo[3].encuadres.forEach(reg=>{
                    encuadres.push({
                        clave:reg.clave,
                        name:reg.criterio,
                        weeks:reg.semanas
                    })
                })
                for(let i=0;i< lengthSemanas;i++){
                    semanas.push("Semana "+(i+1))
                }
                setCalendarWeeks(encuadres)
                setCalendarHeaders(semanas)
                setEditInfo(datos)
                setLoadingEditModal(false)
            }else{
                enqueueSnackbar(datos.m, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                setEditModal(false)
                setLoadingEditModal(false)
            }
        })
    }
    return(
        <Contenedor title='Reporte de Lista de Verificación'>
            <div className='opciones-bar opciones-bar-reporte'>
                <div className='tab-filtros' style={{display: 'block'}}>
                    <div>
                        <MasivaButton onClick={downloadReport}>Descargar</MasivaButton>
                    </div>
                    <div>
                        <p>Año:</p>
                        <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                            <DatePicker views={["year"]} value={year} onChange={setYear} className='date-year' />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div>
                        <p>Ciclo:</p>
                        <Select 
                            options={cycles}
                            className='select-weeks'
                            classNamePrefix='select-search'
                            value={cycle}
                            onChange={setCycle}
                        />
                    </div>
                    <div>
                        <p>Nivel:</p>
                        <Select 
                            options={levels}
                            className='select-weeks'
                            classNamePrefix='select-search'
                            value={level}
                            onChange={setLevel}
                        />
                    </div>
                    <div>
                        <p>Docente:</p>
                        <Select
                            options={docentes}
                            className='select-docente-largo'
                            classNamePrefix='select-search'
                            value={docente}
                            onChange={setDocente}
                        />
                    </div>
                    <div>
                        <p>Módulo:</p>
                        <Select
                            options={modulos}
                            className='select-docente-largo'
                            classNamePrefix='select-search'
                            value={modulo}
                            onChange={handleChangeModulo}
                        />
                    </div>
                    <div>
                        <BlackButton style={{marginLeft: '20px'}} onClick={() => fetchData()}>Buscar</BlackButton>
                    </div>
                    <div style={{float: 'right', paddingTop: '15px', marginLeft: 5, position: 'relative'}}>
                        <SearchInput search={search} onChange={setSearchText} />
                    </div>
                </div>
            </div>
            <div style={{height: 'calc(100% - 100px)'}}>
                <DataTable 
                    headers={headers}
                    data={dataFinal}
                    loading={loading}
                    bordes
                    paginate
                />
            </div>
            <VerificacionModal onEditOpen={editOpen} onRegenerar={openModalRegenerar} onExtra={openModalRegistro} onDownload={downloadFile} title="Lista Verificación" open={openVerificacionModal} height={600} handleClose={() => setOpenVerificacionModal(false)} loading={loadingModal} sendMail={() => openModalCorreoHandle()} showPdf={urlPdfLink}>
                <p style={{textAlign: 'center', lineHeight: 'calc(100vh - 340px)'}}>No existe el PDF.</p>
            </VerificacionModal>
             <LargeModal handleSubmit={() => {}} disabled={false} title='Editar Lista Verificación' open={editModal} handleClose={() => setEditModal(false)} loading={loadingEditModal} height={'85vh'}>
                {
                    wantWeek ? 
                    <div className='Lista-Editable-Container'>
                        <ListaEditable
                            editarSemana={editarSemana}
                            setCalendarWeeks={setCalendarWeeks}
                            weeks={calendarHeaders}
                            criterios={calendarWeeks}
                            maxWeek={maxWeek}
                        />
                    </div>
                    : null
                }
                {
                    verificandoRegeneracion ?
                    <>
                        <div style={{textAlign: 'center', paddingTop: 30}}>
                            <CircularProgress size={50} />
                        </div>
                        <p style={{textAlign: 'center'}}>Iniciando Proceso...</p>
                    </>
                    : null
                }
                {
                    ultimoRegenerando ?
                    <div style={{textAlign: 'center', padding: '30px 50px'}}>
                        <CircularProgress size={50} />
                        <LinearProgress variant="determinate" value={progresoRegenerando} />
                        <p style={{textAlign: 'center'}}>Procesando...</p>
                    </div>
                    : null
                }
            </LargeModal>
            <MailModal log height={600} open={modalRegistro} handleClose={() => setModalRegistro(false)} title='Registro de Ejecución' regresarBtn={regresarBtn}>
                {
                    errorLog ?
                    <div style={{height: 300}}>
                        <p style={{margin: 0, padding: '50px 0', textAlign: 'center'}}>{errorLog}</p>
                    </div> :  
                        (
                            embedLog ?
                            <>
                                <div style={{height: '100%'}}>
                                    <div style={{height: 'calc(100% - 60px)', overflow: 'auto'}}>
                                        <iframe src={urlLog} style={{width: '1000%', height: '100%'}} />
                                    </div>
                                </div>
                                <button className='gray-btn' onClick={() => {
                                    setEmbedLog(false)
                                    setRegresarBtn(true)
                                }} style={{position: 'absolute', bottom: '15px', left: '10px', zIndex: 99}}>Regresar</button>
                            </> :
                            <div style={{height: 'calc(100% - 60px)'}}>
                                <DataTable 
                                    headers={[
                                        {
                                            name: 'Fecha',
                                            col: 0
                                        },
                                        {
                                            name: 'Nombre',
                                            col: 1
                                        },
                                        {
                                            name: 'Link',
                                            col: 2
                                        }
                                    ]}
                                    data={dataRegistro}
                                    loading={modalRegistroDataLoading}
                                />
                            </div> 
                        )
                }
            </MailModal>
            <MailModal height={500} open={mailModal} handleClose={() => setMailModal(false)} title='Enviar Correo' handleSave={sendMail} loading={emailLoading} enviar>
                <div className='nuevoUsuarioForm' style={{position: 'relative', height: 'calc(100% - 60px)', overflowY: 'auto', overflowX: 'hidden'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <label>Correo Institucional:</label>
                            <input className={correoInstitucionalValido ? 'input-clave' : 'input-clave error-input'} value={correoInstitucional} onChange={e => changeCorreoInstitucional(e)} />
                        </Grid>
                        <Grid item xs={12}>
                            <label>Correo Personal:</label>
                            <input className={correoPersonalValido ? 'input-clave' : 'input-clave error-input'} value={correoPersonal} onChange={e => changeCorreoPersonal(e)} />
                        </Grid>
                        <Grid item xs={12}>
                            <label>Otro:</label>
                            <input className={correoOtroValido ? 'input-clave' : 'input-clave error-input'} value={otro} onChange={e => changeCorreoOtro(e)} />
                        </Grid>
                        <Grid item xs={12}>
                            <label>Asunto:</label>
                            <input className={asuntoValido ? 'input-clave' : 'input-clave error-input'} value={asunto} onChange={e => changeAsunto(e)} />
                        </Grid>
                        <Grid item xs={12}>
                            <label>Cuerpo:</label>
                            <textarea className={cuerpoValido ? 'input-clave' : 'input-clave error-input'} value={cuerpo} onChange={e => changeCuerpo(e)}></textarea>
                        </Grid>
                    </Grid>
                </div>
            </MailModal>
            <RegenerarModal open={modalRegenerar} height={215}>
                {
                    wantWeek ?
                    <>
                        <div className='nuevoUsuarioForm'>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <label>Clave Docente</label>
                                    <input value={claveDocenteRegistro} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <label>Clave Curso</label>
                                    <input value={claveCursoRegistro} />
                                </Grid>
                                <Grid item xs={12}>
                                    <label>Semana</label>
                                    <Select 
                                        options={totalWeeks}
                                        placeholder='Selecciona semana...'
                                        className='select-modal'
                                        classNamePrefix='select-modal'
                                        value={regenerarWeek}
                                        onChange={setRegenerarWeek}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className='NewModal-footer'>
                            <button className='gray-btn' onClick={() => setModalRegenerar(false)}>Cancelar</button>
                            <button className='black-btn' onClick={enviarRegeneracion} disabled={regenerarWeek === ''}>Enviar</button>
                        </div>
                    </>
                    : null
                }
                {
                    verificandoRegeneracion ?
                    <>
                        <div style={{textAlign: 'center', paddingTop: 30}}>
                            <CircularProgress size={50} />
                        </div>
                        <p style={{textAlign: 'center'}}>Iniciando Proceso...</p>
                    </>
                    : null
                }
                {
                    ultimoRegenerando ?
                    <div style={{textAlign: 'center', padding: '30px 50px'}}>
                        <CircularProgress size={50} />
                        <LinearProgress variant="determinate" value={progresoRegenerando} />
                        <p style={{textAlign: 'center'}}>Procesando...</p>
                    </div>
                    : null
                }
            </RegenerarModal>
        </Contenedor>
    )
}