import React from 'react';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import './index.css';

export default function LargeModal(props){
    return(
        <Modal open={props.open} onClose={props.handleClose} style={{zIndex: 9999999}} disableBackdropClick>
            <div className='NewModal LargeModal' style={{height: props.height}}>
                <CloseIcon onClick={props.handleClose} />
                <h3>{props.title}</h3>
                <div className='NewModal-container' style={{position: 'relative', height: 'calc(100% - 70px)'}}>
                    {
                        props.loading ?
                        <div style={{textAlign: 'center', paddingTop: 30}}>
                            <CircularProgress size={50} />
                        </div> :
                        <>
                            {props.children}
                            <div className='NewModal-footer'>
                                <button className='gray-btn' onClick={props.handleClose}>Cerrar</button>
                                {
                                    props.submittable ?
                                    <button className='black-btn' disabled={props.disabled} style={props.disabled ? {opacity: '.25'} : null} onClick={props.handleSubmit}>Guardar</button>
                                    : null
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </Modal>
    )
} 