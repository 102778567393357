import React, { useState, useEffect, useContext } from 'react';
import Contenedor from '../../Utils/Visuales/Contenedor';
import NewButton from '../../Utils/Botones/NewButton';
import SearchInput from '../../Utils/Visuales/SearchInput';
import DataTable from '../../Utils/DataTables';
import EditButton from '../../Utils/Botones/EditButton';
import NewModal from '../../Utils/Visuales/NewModal';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LargeModal from '../../Utils/Visuales/LargeModal';
import MailModal from '../../Utils/Visuales/MailModal';
import Calendar from '../../Utils/CalendarTable/Calendar';
import { getRequest, postRequest } from '../../Utils/Funciones/requester';
import { useSnackbar } from 'notistack';
import { cleanup } from '@testing-library/react';
import Can from '../../Utils/Funciones/can';
import NivelesContext from '../../Utils/Providers/NivelesProvider';

export default function Calendario(props){
    const headers = [
        {
            name: 'Año',
            col: 0
        },
        {
            name: 'Ciclo',
            col: 1
        },
        {
            name: 'Nivel',
            col: 2
        },
        {
            name: 'Módulo',
            col: 3
        },
        {
            name: 'Fecha Inicio',
            col: 4
        },
        {
            name: 'Fecha Fin',
            col: 5
        },
        {
            name: 'Total de Semanas',
            col: 6
        },
        {
            name: 'Acción',
            col: 7
        }
    ];
    const [dataOriginal, setDataOriginal] = useState([]);
    const [dataFinal, setDataFinal] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackType, setSnackType] = useState('');
    const [snackMsg, setSnackMsg] = useState('');
    const [tableLoading, setTableLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loadingModificarSemana,setLoadingModificarSemana] = useState(false)
    //New modal states
    const [modalNew, setModalNew] = useState(false);
    const [year, setYear] = useState('');
    const [cycle, setCycle] = useState('')
    const [cambiadoInicio,setCambiadoInicio] = useState(false)
    const [cambiadoFin,setCambiadoFin] = useState(false)
    const cycles = [
        {
            label: '01',
            value: '01'
        },
        {
            label: '02',
            value: '02'
        },
        {
            label: '03',
            value: '03'
        }
    ];
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [startClasDate, setStartClasDate] = useState(moment());
    const [endClasDate, setEndClasDate] = useState(moment());
    const [levels, setLevels] = useState(useContext(NivelesContext).state.levels);
    const [level, setLevel] = useState('');
    const [totalWeeks, setTotalWeeks] = useState(0);
    const [loadingModal, setLoadingModal] = useState(false)

    //Modal Calendario
    const [modalCalendar, setModalCalendar] = useState(false);
    const [calendarId, setCalendarId] = useState('');
    const [calendarLoading, setCalendarLoading] = useState(false);
    const [calendarYear, setCalendarYear] = useState('');
    const [calendarCycle, setCalendarCycle] = useState('');
    const [calendarLevel, setCalendarLevel] = useState('');
    const [calendarCreatedAt, setCalendarCreatedAt] = useState('');
    const [calendarCreatedBy, setCalendarCreatedBy] = useState('');
    const [calendarUpdatedAt, setCalendarUpdatedAt] = useState('');
    const [calendarHeaders, setCalendarHeaders] = useState([]);
    const [calendarWeeks, setCalendarWeeks] = useState([]);
    const [calendarModulo, setCalendarModulo] = useState('');
    const [calendarInitDate, setCalendarInitDate] = useState('');
    const [calendarEndDate, setCalendarEndDate] = useState('');
    const [calendarInitCurDate, setCalendarInitCurDate] = useState('');
    const [calendarEndCurDate, setCalendarEndCurDate] = useState('');
    const [calendarTotalWeeks, setCalendarTotalWeeks] = useState('');

    const [modalModificarSemana,setModificarSemana] = useState(false)
    const [startDateModificarSemana,setStartDateModificarSemana] = useState(moment())
    const [endDateModificarSemana,setEndDateModificarSemana] = useState(moment())
    const [detalleCalendario,setDetalleCalendario] = useState([])
    const [idSemana,setIdSemana] = useState('')

    const [modalModificarCriterio,setModificarCriterio] = useState(false)
    const [startDateModificarCriterio,setStartDateModificarCriterio] = useState(moment())
    const [endDateModificarCriterio,setEndDateModificarCriterio] = useState(moment())
    const [loadingModificarCriterio,setLoadingModificarCriterio] = useState(false)
    const [semanaEdit,setSemanaEdit] = useState(-1)
    const [criterioEdit,setCriterioEdit] = useState("")
    const [maxDate,setMaxDate] = useState(moment())
    const [minDate,setMinDate] = useState(moment())
    const [criterioId,setCriterioId] = useState("")
    const [modules, setModules] = useState([
        {
            label: 'A',
            value: 'A'
        },
        {
            label: 'A1',
            value: 'A1'
        },
        {
            label: 'B',
            value: 'B'
        },
        {
            label: 'B1',
            value: 'B1'
        },
        {
            label: 'Longitudinal',
            value: 'Transversal'
        },
        {
            label: 'Longitudinal1',
            value: 'Transversal1'
        }
    ])
    const [modulo, setModulo] = useState('')

    const [modalConfirmacion, setModalConfirmacion] = useState(false);

    useEffect(() => {
        const pageController = new AbortController()
        const pageSignal = pageController.signal
        async function fetchData(){
            try {
                setTableLoading(true);
                let response = await getRequest('/calendario', pageSignal);
                if(response.s === 'OK'){
                    let data = []
                    if(response.d){
                        data = response.d.map(row => {
                            if(row.modulo==='Transversal'){
                                row.modulo='Longitudinal'
                            }
                            return [
                                row.año, 
                                row.ciclo, 
                                row.nivel, 
                                row.modulo, 
                                moment(row.fecha_inicio).format('DD-MM-YYYY'), 
                                moment(row.fecha_fin).format('DD-MM-YYYY'), 
                                row.total_semanas, 
                                <>
                                    <Can I='edit' a='calendario'>
                                        <EditButton onClick={() => openModalCalendar(row.id)}>Criterios</EditButton>
                                    </Can>
                                </>]
                        });
                    }
                    setDataOriginal(data);
                    setDataFinal(data);
                    setTableLoading(false);
                } else {
                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            } catch (error) {

            }
        }
        fetchData();
        return function cleanup(){
            pageController.abort()
        }
    }, [refresh])

    useEffect(() => {
        let firstMonday = moment(startClasDate).isoWeekday('Monday');
        let latestSunday = moment(endClasDate).isoWeekday('Sunday');
        let diff = latestSunday.diff(firstMonday, 'weeks', true);
        setTotalWeeks(Math.ceil(diff))
    }, [startClasDate, endClasDate])

    function handleChangeStart(pickerDate){
        let date = moment(pickerDate);
        if(date.isSameOrBefore(endDate, 'day')){
            setStartDate(date);
        } else {
            setSnackType('error');
            setSnackMsg('Fecha de inicio debe ser antes de fecha final');
            setSnackOpen(true);
        }
    }

    function handleChangeClaStart(pickerDate){
        let date = moment(pickerDate);
        if(date.isSameOrBefore(endClasDate, 'day')){
            setStartClasDate(date);
        } else {
            setSnackType('error');
            setSnackMsg('Fecha de inicio debe ser antes de fecha final');
            setSnackOpen(true);
        }
    }

    function handleChangeEnd(pickerDate){
        let date = moment(pickerDate);
        if(date.isSameOrAfter(startDate, 'day')){
            setEndDate(date);
        } else {
            setSnackType('error');
            setSnackMsg('Fecha final debe ser después de fecha de inicio');
            setSnackOpen(true);
        }
    }


    function handleChangeClaStartModificarSemana(pickerDate){
        let date = moment(pickerDate);
        if(date.isSameOrBefore(endDateModificarSemana, 'day')){
            setStartDateModificarSemana(date);
        } else {
            setSnackType('error');
            setSnackMsg('Fecha de inicio debe ser antes de fecha final');
            setSnackOpen(true);
        }
    }

    function handleChangeEndModificarSemana(pickerDate){
        let date = moment(pickerDate);
        if(date.isSameOrAfter(startDateModificarSemana, 'day')){
            setEndDateModificarSemana(date);
        } else {
            setSnackType('error');
            setSnackMsg('Fecha final debe ser después de fecha de inicio');
            setSnackOpen(true);
        }
    }
    function handleChangeClaStartModificarCriterio(pickerDate){
        let date = moment(pickerDate);
        if(date.isSameOrBefore(endDateModificarCriterio, 'day') || !cambiadoFin){
            setStartDateModificarCriterio(date);
            setCambiadoInicio(true)
        } else {
            setSnackType('error');
            setSnackMsg('Fecha de inicio debe ser antes de fecha final');
            setSnackOpen(true);
        }
    }

    function handleChangeEndModificarCriterio(pickerDate){
        let date = moment(pickerDate);
        if(date.isSameOrAfter(startDateModificarCriterio, 'day') || !cambiadoInicio){
            setEndDateModificarCriterio(date);
            setCambiadoFin(true)
        } else {
            setSnackType('error');
            setSnackMsg('Fecha final debe ser después de fecha de inicio');
            setSnackOpen(true);
        }
    }


    function handleChangeClaEnd(pickerDate){
        let date = moment(pickerDate);
        if(date.isSameOrAfter(startClasDate, 'day')){
            setEndClasDate(date);
        } else {
            setSnackType('error');
            setSnackMsg('Fecha final debe ser después de fecha de inicio');
            setSnackOpen(true);
        }
    }

    function search(text){
        text = text.toLowerCase();
        let result = dataOriginal.filter(row => {
            if(row[0].toString().toLowerCase().includes(text)) return true;
            else if(row[1].toString().toLowerCase().includes(text)) return true;
            else if(row[2].toLowerCase().includes(text)) return true;
            else if(row[3].toLowerCase().includes(text)) return true;
            else if(row[4].toLowerCase().includes(text)) return true;
            else if(row[5].toString().toLowerCase().includes(text)) return true;
            else return false
        })
        setDataFinal(result);
    }

    async function openModalCalendar(id){
        setCalendarLoading(true);
        setModalCalendar(true);
        let response = await getRequest(`/calendario/detalle?id=${id}`);
        if(response.s === 'OK'){
            setCalendarId(response.d.detalle.id);
            setCalendarLevel(response.d.detalle.nivel);
            setCalendarYear(response.d.detalle.year);
            setCalendarCycle(response.d.detalle.ciclo);
            setCalendarCreatedBy(response.d.detalle.creado_por);
            setCalendarCreatedAt(moment(response.d.detalle.fecha_creado).format('DD-MM-YYYY'));
            setCalendarUpdatedAt(moment(response.d.detalle.fecha_actualizado).format('DD-MM-YYYY'));
            if(response.d.detalle.modulo==="Transversal"){
                setCalendarModulo('Longitudinal')
            }else{
                setCalendarModulo(response.d.detalle.modulo);
            }
            setCalendarInitCurDate(moment(response.d.detalle.fecha_inicio_clases).format('DD-MM-YYYY'));
            setCalendarEndCurDate(moment(response.d.detalle.fecha_fin_clases).format('DD-MM-YYYY'));
            setCalendarInitDate(moment(response.d.detalle.fecha_inicio_curso).format('DD-MM-YYYY'));
            setCalendarEndDate(moment(response.d.detalle.fecha_fin_curso).format('DD-MM-YYYY'));
            setCalendarTotalWeeks(response.d.detalle.total_semanas);
            let criteriosList = response.d.criterios.map(criterio => (
                {
                    name: criterio.descripcion,
                    clave: criterio.clave,
                    weeks: []
                }
            ));
            let weeksQuantity = response.d.detalle.calendario[response.d.detalle.calendario.length-1].semana;
            for(let i = 0; i < criteriosList.length; i++){
                let weeksCriterios = response.d.detalle.calendario.filter(row => (
                    row.criterios[0].clave_criterio === criteriosList[i].clave
                ))
                for(let j = 0; j < weeksCriterios.length; j++){
                    criteriosList[i].weeks.push(weeksCriterios[j].criterios[0].activo);
                }
            }
            let weeksHeader = [];
            for(let i = 0; i < weeksQuantity; i++){
                weeksHeader.push(`Semana ${i+1}`)
            }
            setDetalleCalendario(response.d.detalle.calendario)
            setCalendarHeaders(weeksHeader);
            setCalendarWeeks(criteriosList);
            setCalendarLoading(false);
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }
    }

    async function sendCalendar(){
        setModalConfirmacion(false);
        let matrix = document.getElementsByClassName('Calendar-Cell');
        let calendario = [];
        for(let i = 0; i < calendarHeaders.length; i++){
            let semana = {
                semana: i+1,
                criterios: []
            };
            calendario.push(semana);
        }
        for(let i = 0; i < matrix.length; i = i + calendarHeaders.length){
            for(let j = 0; j < calendarHeaders.length; j++){
                let clave = matrix[i+j].getAttribute('clave');
                let bool = matrix[i+j].classList.contains('selected');
                calendario[j].criterios.push({
                    clave_criterio: clave,
                    activo: bool
                });
            }
        }
        let json = {
            id: calendarId,
            calendario: calendario
        }
        setCalendarLoading(true);
        let response = await postRequest('/calendario/editar', json);
        if(response.s === 'OK'){
            setModalCalendar(false);
            enqueueSnackbar(`Calendario actualizado correctamente.`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setRefresh(refresh+1);
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }
    }

    useEffect(() => {
        setLevel('')
        setYear('')
        setCycle('')
        setModulo('')
        setStartDate(moment())
        setEndDate(moment())
    }, [modalNew])


    async function sendNuevoCurso(){
        let valido=true

        if(level===''){
            document.getElementsByClassName('input-level')[0].classList.add('error-input')
            valido = false
        }else {
            document.getElementsByClassName('input-level')[0].classList.remove('error-input')
        }
        if(year === ''){
            document.getElementsByClassName('input-year')[0].classList.add('error-input')
            valido = false
        }else{
            document.getElementsByClassName('input-year')[0].classList.remove('error-input')
        }

        if(modulo === ''){
            document.getElementsByClassName('input-modulo')[0].classList.add('error-input')
            valido = false
        } else {
            document.getElementsByClassName('input-modulo')[0].classList.remove('error-input')
        }

        if(cycle === ''){
            document.getElementsByClassName('input-cycle')[0].classList.add('error-input')
            valido = false
        } else {
            document.getElementsByClassName('input-cycle')[0].classList.remove('error-input')
        }

        if(valido){
            let json={
                nivel: level.value,
                year: year,
                ciclo:cycle.value,
                fecha_fin:moment(endDate).format('YYYY-MM-DD'),
                fecha_inicio:moment(startDate).format('YYYY-MM-DD'),
                fecha_inicio_clases: moment(startClasDate).format('YYYY-MM-DD'),
                fecha_fin_clases: moment(endClasDate).format('YYYY-MM-DD'),
                modulo: modulo.value,
                total_semanas: totalWeeks
            }
            setLoadingModal(true)
            let response = await postRequest('/calendario/nuevo', json)
            setLoadingModal(false)
            if(response.s === 'OK'){
                setModalNew(false)
                setRefresh(refresh+1)
            }else{
                setSnackMsg(response.m)
                setSnackType('error')
                setSnackOpen(true)
            }
        }
    }

    async function openModalNewCalendar(){
        setLoadingModal(true);
        setModalNew(true);
        setLoadingModal(false);
    }
    async function handleOpenModificarSemana(semana){
        let myFechaInicio=""
        let myFechaFin=""
        for (let i=0;i<detalleCalendario.length;i++){
            if(detalleCalendario[i].semana === semana+1){
                myFechaInicio=detalleCalendario[i].fecha_inicio
                myFechaFin=detalleCalendario[i].fecha_fin
                setIdSemana(detalleCalendario[i].id)
                i=detalleCalendario.length+1
            }
        }
        if(myFechaInicio != ""){
            setStartDateModificarSemana(moment(myFechaInicio))
        }else{
            setStartDateModificarSemana(moment())
        }
        if(myFechaFin != ""){
            setEndDateModificarSemana(moment(myFechaFin))
        }else{
            setEndDateModificarSemana(moment())
        }
        setModificarSemana(true)
    }

    async function handleOpenModificarCriterio(semana,criterio){
        let myFechaInicio=""
        let myFechaFin=""
        let myMinimo=""
        let myMaximo=""
        for (let i=0; i<detalleCalendario.length; i++){
            for (let j=0; j<detalleCalendario[i].criterios.length; j++){
                if(detalleCalendario[i].criterios[j].clave_criterio===criterio.clave && semana+1==detalleCalendario[i].semana){
                    myFechaInicio=detalleCalendario[i].criterios[j].fecha_inicio
                    myFechaFin=detalleCalendario[i].criterios[j].fecha_fin
                    setCriterioId(detalleCalendario[i].criterios[j].id)
                    myMinimo=detalleCalendario[i].fecha_inicio
                    myMaximo=detalleCalendario[i].fecha_fin
                    break;
                }
            }
        }

        if(myMaximo === "" || myMinimo === ""){
            enqueueSnackbar("Se debe definir primero intervalo de fechas para la semana "+(semana+1), {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            return
        }

        setSemanaEdit(semana+1)
        setCriterioEdit(criterio.clave)
        
        if(myMinimo != ""){
            setMinDate(moment(myMinimo))
        }else{
            setMinDate(moment())
        }
        if(myMaximo != ""){
            setMaxDate(moment(myMaximo))
        }else{
            setMaxDate(moment())
        }

        if(myFechaInicio != ""){
            setStartDateModificarCriterio(moment(myFechaInicio))
        }else{
            setStartDateModificarCriterio(moment(myMinimo+' 00:00'))
        }
        if(myFechaFin != ""){
            setEndDateModificarCriterio(moment(myFechaFin))
        }else{
            setEndDateModificarCriterio(moment(myMinimo+' 00:00'))
        }

        setModificarCriterio(true)
    }
    async function sendModificarSemana(){
        let body={
            fecha_inicio:moment(startDateModificarSemana).format('YYYY-MM-DD'),
            fecha_fin:moment(endDateModificarSemana).format('YYYY-MM-DD'),
            id_semana_calendario:idSemana
        }
        setLoadingModificarSemana(true)
        let response = await postRequest('/calendario/editar/semana',body)
        if(response.s==="OK"){
            setLoadingModificarSemana(false)
            setModificarSemana(false)
            enqueueSnackbar(response.m, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            openModalCalendar(calendarId)
        }else{
            setLoadingModificarSemana(false)
            //setModificarSemana(false)
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
           // openModalCalendar(calendarId)  
        }
    }
    async function sendModificarCriterio(){
        let body={
            fecha_inicio:moment(startDateModificarCriterio).format('YYYY-MM-DD HH:mm'),
            fecha_fin:moment(endDateModificarCriterio).format('YYYY-MM-DD HH:mm'),
            id_calendario_criterio:criterioId
        }
        setLoadingModificarCriterio(true)
        let response = await postRequest('/calendario/editar/criterio',body)
        if(response.s==="OK"){
            setLoadingModificarCriterio(false)
            setModificarCriterio(false)
            enqueueSnackbar(response.m, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            openModalCalendar(calendarId)
        }else{
            setLoadingModificarCriterio(false)
            //setModificarSemana(false)
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
           // openModalCalendar(calendarId)  
        }
    }

    return (
        <Contenedor title='Calendario'>
            <div className='opciones-bar'>
                <div style={{float: 'left'}}>
                    <Can I='create' a='calendario'>
                        <NewButton onClick={openModalNewCalendar}>Nuevo</NewButton>
                    </Can>
                </div>
                <div style={{float: 'right'}}>
                    <SearchInput search={search} />
                </div>
            </div>
            <div style={{height: 'calc(100% - 75px)'}}>
                <DataTable
                    headers={headers}
                    data={dataFinal}
                    centrar={[5,6,7]}
                    loading={tableLoading}
                />
            </div>
            <NewModal title='Nuevo Calendario' open={modalNew} handleSubmit={sendNuevoCurso} loading={loadingModal} handleClose={() => setModalNew(false)} height={390}>
                <div className='nuevoUsuarioForm'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <label>Año (*)</label>
                            <input maxLength='2' placeholder='Ingresa el año...' className='input-year' value={year} onChange={e => setYear(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Ciclo (*)</label>
                            <Select 
                                options={cycles}
                                placeholder='Seleccione ciclo...'
                                className='select-modal input-cycle'
                                classNamePrefix='select-modal'
                                value={cycle}
                                onChange={setCycle}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Nivel (*)</label>
                            <Select
                                options={levels}
                                placeholder='Seleccione nivel...'
                                className='select-modal input-level'
                                classNamePrefix='select-modal'
                                value={level}
                                onChange={setLevel}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Módulo (*)</label>
                            <Select 
                                options={modules}
                                placeholder='Seleccione módulo...'
                                className='select-modal input-modulo'
                                classNamePrefix='select-modal'
                                value={modulo}
                                onChange={setModulo}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Fecha Inicio (*)</label>
                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} style={{zIndex: 999999999}}>
                                <DatePicker clearLabel="Limpiar" cancelLabel="Cancelar" className='date-picker' clearable format='dd/MM/yyyy' value={startDate} onChange={handleChangeStart} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Fecha Fin (*)</label>
                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} style={{zIndex: 999999999}}>
                                <DatePicker clearLabel="Limpiar" cancelLabel="Cancelar" className='date-picker' clearable format='dd/MM/yyyy' value={endDate} onChange={handleChangeEnd} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Fecha Inicio Clases (*)</label>
                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} style={{zIndex: 99999999}}>
                                <DatePicker clearLabel="Limpiar" cancellabel="Cancelar" className="date-picker" clearable format='dd/MM/yyyy' value={startClasDate} onChange={handleChangeClaStart} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Fecha Fin Clases (*)</label>
                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} style={{zIndex: 9999999}}>
                                <DatePicker clearLabel="Limpiar" cancellabel="Cancelar" className="date-picker" clearable format='dd/MM/yyyy' value={endClasDate} onChange={handleChangeClaEnd} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Total Semanas:</label>
                            <input value={totalWeeks} onChange={e => setTotalWeeks(e.target.value)} />
                        </Grid>
                    </Grid>
                </div>
                <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} key='top,right' open={snackOpen} onClose={() => setSnackOpen(false)} autoHideDuration={2000}>
                    <MuiAlert severity={snackType}>
                        {snackMsg}
                    </MuiAlert>
                </Snackbar>
            </NewModal>
            <LargeModal handleSubmit={() => setModalConfirmacion(true)} submittable disabled={false} title='Calendario de Criterios' open={modalCalendar} handleClose={() => setModalCalendar(false)} loading={calendarLoading} height={'75vh'}>
                <div className='opciones-bar inputs-modal inputs-calendar'>
                    <div style={{float: 'left'}}>
                        <label>Año:</label>
                        <input style={{width: 38}} value={calendarYear} disabled />
                        <label>Ciclo:</label>
                        <input style={{width: 38}} value={calendarCycle} disabled />
                        <label>Nivel:</label>
                        <input style={{width: 38}} value={calendarLevel} disabled />
                    </div>
                    <div style={{float: 'right', marginRight: 20}}>
                        <label>Fecha Inicio:</label>
                        <input style={{width: 78}} value={calendarInitDate} disabled />
                        <label>Fecha Fin:</label>
                        <input style={{width: 78}} value={calendarEndDate} disabled />
                        <label>Fecha Inicio Clases:</label>
                        <input style={{width: 78}} value={calendarInitCurDate} disabled />
                        <label>Fecha Fin Clases:</label>
                        <input style={{width: 78}} value={calendarEndCurDate} disabled />
                    </div>
                </div>
                <div className='opciones-bar inputs-modal inputs-calendar'>
                    <div style={{float: 'left'}}>
                        <label>Total Semanas Nivel:</label>
                        <input style={{width: 38}} value={calendarTotalWeeks} disabled />
                        <label>Modulo:</label>
                        <input style={{width: 85}} value={calendarModulo} disabled />
                    </div>
                    <div style={{float: 'right', marginRight: 20}}>
                        <label>Fecha creado:</label>
                        <input style={{width: 78}} value={calendarCreatedAt} disabled />
                        <label>Creado por:</label>
                        <input sttyle={{width: 80}} value={calendarCreatedBy} disabled />
                        <label>Actualizado:</label>
                        <input style={{width: 78}} value={calendarUpdatedAt} disabled />
                    </div>
                </div>
                <div className='Calendar-Container'>
                    <Calendar
                        handleOpenModificarCriterio={handleOpenModificarCriterio}
                        handleOpenModificarSemana={handleOpenModificarSemana}
                        weeks={calendarHeaders}
                        criterios={calendarWeeks}
                        detalleCalendario={detalleCalendario}
                    />
                </div>
            </LargeModal>
            <MailModal title='Confirmación' open={modalConfirmacion} handleClose={() => setModalConfirmacion(false)} height={95} handleSave={sendCalendar}>
                <p style={{textAlign: 'center'}}>¿Deseas guardar los cambios realizados?</p>
            </MailModal>
            <NewModal 
                title='Modificar semana' 
                open={modalModificarSemana} 
                handleSubmit={sendModificarSemana} 
                loading={loadingModificarSemana} 
                handleClose={() => setModificarSemana(false)} 
                height={170}>
                <div className='nuevoUsuarioForm'>
                <Grid container>
                        <Grid item xs={12} md={6}>
                            <label>Fecha Inicio (*)</label>
                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} style={{zIndex: 99999999}}>
                                <DatePicker clearLabel="Limpiar" cancelLabel="Cancelar" className='date-picker' clearable format='dd/MM/yyyy' value={startDateModificarSemana} onChange={handleChangeClaStartModificarSemana} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Fecha Fin (*)</label>
                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} style={{zIndex: 99999999}}>
                                <DatePicker clearLabel="Limpiar" cancelLabel="Cancelar" className='date-picker' clearable format='dd/MM/yyyy' value={endDateModificarSemana} onChange={handleChangeEndModificarSemana} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                </Grid>
                </div>
            </NewModal>
            <NewModal 
                title='Modificar Criterio' 
                open={modalModificarCriterio} 
                handleSubmit={sendModificarCriterio} 
                loading={loadingModificarCriterio} 
                handleClose={() => setModificarCriterio(false)} 
                height={170}>
                <div className='nuevoUsuarioForm'>
                <Grid container>
                        <Grid item xs={12} md={12}>  
                            <h4>Configuración de semana {semanaEdit} criterio {criterioEdit}</h4>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Fecha Inicio (*)</label>
                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} style={{zIndex: 99999999}}>
                                <DateTimePicker minDate={minDate} maxDate={maxDate} clearLabel="Limpiar" cancelLabel="Cancelar" className='date-picker' clearable format='dd/MM/yyyy HH:mm' value={startDateModificarCriterio} onChange={handleChangeClaStartModificarCriterio} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Fecha Fin (*)</label>
                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} style={{zIndex: 99999999}}>
                                <DateTimePicker minDate={minDate} maxDate={maxDate} clearLabel="Limpiar" cancelLabel="Cancelar" className='date-picker' clearable format='dd/MM/yyyy HH:mm' value={endDateModificarCriterio} onChange={handleChangeEndModificarCriterio} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                </Grid>
                </div>
            </NewModal>
        </Contenedor>
    )
}
