import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import {Table, TableBody, TableRow, TableCell, TableHead} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import './index.css';
export default function DataSubTable(props){
    const data = props.data;
    const [orderBy, setOrderBy] = useState({col: 0, asc: true})
    const [rowsPerPage, setRowsPerPage] = useState(data.length)
    const [pageActual, setPageActual] = useState(0)
    const [refresh, setRefresh] = useState(true);
    const tableRef = useRef(null)
    let i = 0
    let j = 0
    let centrados = props.centrar ? props.centrar : []

    const [width, height] = useTableSize()

    useEffect(() => {
        let outTable
        let rowHeight
        if(width > 1200){
            outTable = 318
            rowHeight = 53
        } else {
            outTable = 350
            rowHeight = 55
        }
        console.log(width, height)
        if(props.paginate){
            setRowsPerPage(Math.trunc((height-outTable)/rowHeight))
        } else {
            setRowsPerPage(data.length)
        }
    }, [width, height, data, props.paginate])

    useEffect(() => {
        if(orderBy.asc){
            data.sort((a,b) => {
                if(orderBy.semana){
                    if(orderBy.avance){
                        a = a[6][orderBy.col-1].alcance;
                        b = b[6][orderBy.col-1].alcance;
                    } else {
                        a = a[6][orderBy.col-1]['desempeño'];
                        b = b[6][orderBy.col-1]['desempeño'];
                    }
                } else {
                    a = a[orderBy.col]
                    b = b[orderBy.col]
                }

                debugger


                while(typeof a === 'object'){
                    a = a.props.children;
                }

                while(typeof b === 'object'){
                    b = b.props.children;
                }

                if(typeof a !== 'number' && typeof b !== 'number') {
                    if(a.includes('%')){
                        a = a.split('%')[0]
                    }
    
                    if(b.includes('%')){
                        b = b.split('%')[0]
                    }
        
                    if(!isNaN(a) && !isNaN(b)){
                        a = parseFloat(a)
                        b = parseFloat(b)
                    }
                }
    
                if(a === 'N/A'){
                    return 1
                }
    
                if(b === 'N/A'){
                    return -1
                }
                
                if(a > b){
                    return 1
                }
                if(a < b){
                    return -1
                }
                return 0
                
            })
        } else {
            data.sort((a,b) => {
                if(orderBy.semana){
                    if(orderBy.avance){
                        a = a[6][orderBy.col-1].alcance;
                        b = b[6][orderBy.col-1].alcance;
                    } else {
                        a = a[6][orderBy.col-1]['desempeño'];
                        b = b[6][orderBy.col-1]['desempeño'];
                    }
                } else {
                    a = a[orderBy.col]
                    b = b[orderBy.col]
                }

                while(typeof a === 'object'){
                    a = a.props.children;
                }

                while(typeof b === 'object'){
                    b = b.props.children;
                }
    
                if(typeof a !== 'number' && typeof b !== 'number'){
                    if(a.includes('%')){
                        a = a.split('%')[0]
                    }
        
                    if(b.includes('%')){
                        b = b.split('%')[0]
                    }
        
                    if(!isNaN(a) && !isNaN(b)){
                        a = parseFloat(a)
                        b = parseFloat(b)
                    }
                }
    
                if(a === 'N/A'){
                    return 1
                }
    
                if(b === 'N/A'){
                    return -1
                }
                
                if(a < b){
                    return 1
                }
                if(a > b){
                    return -1
                }
                return 0
            })
        }
        setRefresh(!refresh);
    }, [orderBy])

    function useTableSize(){
        const [size, setSize] = useState([0, 0])
        useLayoutEffect(() => {
            function updateSize(){
                setSize([window.innerWidth, window.innerHeight])
            }
            window.addEventListener('resize', updateSize)
            updateSize()
            return () => window.removeEventListener('resize', updateSize)
        }, [])
        return size
    }

    function getRows(){
        let rows = []
        let maxLength = pageActual < Math.ceil(data.length/rowsPerPage) - 1 ? (rowsPerPage*pageActual)+rowsPerPage : data.length;
        if(data.length > 0){ 
            for(i = rowsPerPage*pageActual; i < maxLength; i++){  
                j = 0
                rows.push(
                    <TableRow>
                        {data[i].slice(0,6).map(cell => {
                            j++
                            return <TableCell style={{height: 45, padding: 0}} align='center'>{cell}</TableCell>    
                        }
                        )}
                    </TableRow>    
                )
            }
        } else {
            return null
        }
        return rows
    }

    function getClassColor(alcance,estatus){
        let className
        if(estatus === "False"){
            className = 'span-rows alcance-grey'
            return className
        }
        if(alcance > 75){
            className = 'span-rows alcance-green'
        } else if (alcance >= 30 && alcance <= 75){
            className = 'span-rows alcance-yellow'
        } else {
            className = 'span-rows alcance-red'
        }
        return className
    }

    function getRows2(){
        let rows = []
        let maxLength = pageActual < Math.ceil(data.length/rowsPerPage) - 1 ? (rowsPerPage*pageActual)+rowsPerPage : data.length;
        if(data.length > 0){  

            rows.push(
                <TableRow className="subHeaderContainer">
                    {props.semanas.map(semana => {  
                            return <TableCell className='HeadCell'>
                                <TableCell className="subHeaders subHeaderL" style={{left: '-10px'}} onClick={() => {
                                    if(parseInt(semana.split(' ')[1]) === orderBy.col){
                                        setOrderBy({
                                            semana: true,
                                            col: parseInt(semana.split(' ')[1]),
                                            asc: !orderBy.asc,
                                            avance: true
                                        })
                                    } else {
                                        setOrderBy({
                                            semana: true,
                                            col: parseInt(semana.split(' ')[1]),
                                            asc: true,
                                            avance: true
                                        })
                                    }
                                }}>
                                    Avance
                                    <div className='HeadArrows-con'>
                                        <ArrowDropUpIcon onClick={e => {
                                            setOrderBy({
                                                semana: true,
                                                col: parseInt(semana.split(' ')[1]),
                                                asc: true,
                                                avance: true
                                            })
                                        }} style={orderBy.col === parseInt(semana.split(' ')[1]) && orderBy.asc && orderBy.avance ? {color: 'red'} : null} />
                                        <ArrowDropDownIcon onClick={e => {
                                            setOrderBy({
                                                semana: true,
                                                col: parseInt(semana.split(' ')[1]),
                                                asc: false,
                                                avance: true
                                            })
                                        }} style={orderBy.col === parseInt(semana.split(' ')[1]) && !orderBy.asc && orderBy.avance ? {color: 'red'} : null} />
                                    </div>
                                </TableCell>
                                <TableCell className="subHeaders subHeaderR" onClick={() => {
                                    if(parseInt(semana.split(' ')[1]) === orderBy.col){
                                        setOrderBy({
                                            semana: true,
                                            col: parseInt(semana.split(' ')[1]),
                                            asc: !orderBy.asc,
                                            avance: false
                                        })
                                    } else {
                                        setOrderBy({
                                            semana: true,
                                            col: parseInt(semana.split(' ')[1]),
                                            asc: true,
                                            avance: false
                                        })
                                    }
                                }}>
                                    Desempeño
                                    <div className='HeadArrows-con'>
                                        <ArrowDropUpIcon onClick={e => {
                                            setOrderBy({
                                                semana: true,
                                                col: parseInt(semana.split(' ')[1]),
                                                asc: true,
                                                avance: false
                                            })
                                        }} style={orderBy.col === parseInt(semana.split(' ')[1]) && orderBy.asc && !orderBy.avance && orderBy.semana ? {color: 'red'} : null} />
                                        <ArrowDropDownIcon onClick={e => {
                                            setOrderBy({
                                                semana: true,
                                                col: parseInt(semana.split(' ')[1]),
                                                asc: false,
                                                avance: false
                                            })
                                        }} style={orderBy.col === parseInt(semana.split(' ')[1]) && !orderBy.asc && orderBy.semana && !orderBy.avance ? {color: 'red'} : null} />
                                    </div>
                                </TableCell>
                            </TableCell>   
                        }
                    )}
                </TableRow> 
            )
            for(i = rowsPerPage*pageActual; i < maxLength; i++){
                rows.push(
                    <>

                    <TableRow>
                        {data[i][6].map(cell => {
                            return <TableCell style={{height: 45}} className="doble-row"><TableCell className={getClassColor(cell["alcance"],cell["estatus"])} align={'center'}>{cell["alcance"]}</TableCell><TableCell align={'center'} className="span-rows sprr">{cell["estatus"] === "False" && cell["desempeño"] === "" ? "Baja" : cell["desempeño"] }</TableCell></TableCell>   
                        }
                        )}
                    </TableRow>    
                    </>
                )
            }
        } else {
            return null
        }
        return rows
    }        
    
    function getPaginate(){
        let buttons = []
        buttons.push(
            <button onClick={() => {
                if(pageActual > 0){
                    setPageActual(pageActual-1)
                }
            }}>Anterior</button>
        )
        if(Math.ceil(data.length/rowsPerPage) > 6){
            if(pageActual <= 2){
                for(i = 0; i < 3; i++){
                    buttons.push(
                        <button onClick={setPageActual.bind(this, i)} className={pageActual === i ? 'active' : null}>{i+1}</button>
                    )
                }
                buttons.push(
                    <button>...</button>
                )
            } else if (pageActual >= Math.ceil(data.length/rowsPerPage)-3){
                buttons.push(
                    <button>...</button>
                )
                for(i = Math.ceil(data.length/rowsPerPage) - 3; i < Math.ceil(data.length/rowsPerPage); i++){
                    buttons.push(
                        <button onClick={setPageActual.bind(this, i)} className={pageActual === i ? 'active' : null}>{i+1}</button>
                    )
                }
            } else {
                buttons.push(
                    <button>...</button>
                )
                for(i = pageActual - 2; i < pageActual + 3; i++){
                    buttons.push(
                        <button onClick={setPageActual.bind(this, i)} className={pageActual === i ? 'active' : null}>{i+1}</button>
                    )
                }
                buttons.push(
                    <button>...</button>
                )
            }
        } else {
            for(i = 0; i < Math.ceil(data.length/rowsPerPage); i++){
                buttons.push(
                    <button onClick={setPageActual.bind(this, i)} className={pageActual === i ? 'active' : null}>{i+1}</button>
                )
            }
        }
        buttons.push(
            <button onClick={() => {
                if(pageActual < data.length/rowsPerPage - 1){
                    setPageActual(pageActual+1)
                }
            }}>Siguiente</button>
        )

        return <div className='contenedor-buttons'>{buttons}</div>
    }

    return(
        <>
            <div ref={tableRef} style={props.paginate ? {height: 'calc(100% - 10px)', overflow: 'hidden', position: 'relative'} : {height: '100%', overflow: 'auto', position: 'relative'}}>
                <div className='STLeft'>
                <Table stickyHeader className='subTable-dash'>
                    <TableHead>
                        <TableRow>
                            {props.headers.map(header => {
                                return(
                                    <TableCell key={header.name} className='HeadCell' col={i++} onClick={e => {
                                        let col = e.target.getAttribute('col');
                                        if(!isNaN(parseInt(col))){
                                            if(orderBy.col === header.col){
                                                setOrderBy({
                                                    col: orderBy.col,
                                                    asc: !orderBy.asc
                                                })
                                            } else {
                                                setOrderBy({
                                                    col: header.col,
                                                    asc: true
                                                })
                                            }
                                        }
                                    }}>
                                        {header.name}
                                        <div className='HeadArrows HeadArrowsST'>
                                            <ArrowDropUpIcon onClick={e => {
                                                setOrderBy({
                                                    col: header.col,
                                                    asc: true
                                                })
                                            }} style={orderBy.col === i-1 && orderBy.asc ? {color: 'red'} : null} />
                                            <ArrowDropDownIcon onClick={e => {
                                                setOrderBy({
                                                    col: header.col,
                                                    asc: false
                                                })
                                            }} style={orderBy.col === i-1 && !orderBy.asc ? {color: 'red'} : null} />
                                        </div>
                                    </TableCell>    
                                )}
                            )}
                        </TableRow>
                    </TableHead>
                    {
                        props.loading ?
                        null :
                        <TableBody>
                            {getRows()}
                        </TableBody>
                    }
                </Table>
                </div>
                <div className="STRight">
                <Table stickyHeader className='subTable-dash'>
                    <TableHead>
                        <TableRow>
                            {props.semanas.map(header => {
                                return(
                                    <TableCell key={header} className='HeadCell'>
                                        {header}
                                    </TableCell>    
                                )}
                            )}
                        </TableRow>
                    </TableHead>
                    {
                        props.loading ?
                        null :
                        <TableBody>
                            {getRows2()}
                        </TableBody>
                    }
                </Table>  
                </div>                                 
                {
                    props.loading ?
                    <div style={{textAlign: 'center', marginTop: '100px', position: 'absolute', width: '100%'}}>
                        <CircularProgress size={50} />
                    </div> :
                    null
                }
                {
                    data.length === 0 && !props.loading ?
                    <p style={{textAlign: 'center', width: '100%', position: 'absolute', marginTop: 80}}>No hay registros que mostrar.</p> :
                    null
                }
            </div>
            {
                props.paginate ?
                getPaginate() : 
                null
            }
        </>
    )
}