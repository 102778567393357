import React, {memo} from 'react';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import './index.css';
import Can from '../../Funciones/can';
import moment from 'moment'

export const VerificacionModal = React.memo(props => {
    return(
        <Modal open={props.open} onClose={props.handleClose} style={{zIndex: 9999999}} disableBackdropClick>
            <div className='NewModal verificacion-modal'>
                <CloseIcon onClick={props.handleClose} />
                <h3>{props.title}</h3>
                <div className='NewModal-container ' style={{height: props.height, position: 'relative'}}>
                    {
                        props.loading ?
                        <div style={{textAlign: 'center', paddingTop: 30}}>
                            <CircularProgress size={50} />
                        </div> :
                        <>
                            {props.showPdf ? 
                                <iframe src={props.showPdf} /> :
                                props.children
                            }
                            <div className='NewModal-footer'>
                                <Can I='create' a='reporte_listaverificacion_regenerarreporte'>
                                    <button className='gray-btn regenerar-btn' style={{position: 'absolute', left: 50}} onClick={props.onRegenerar}>Volver a Generar</button>
                                </Can>
                                    <button className='gray-btn editable-btn' onClick={props.onEditOpen}>Abrir editable</button>
                                <Can I='create' a='reporte_listaverificacion_enviarcorreo'>
                                    <button className='gray-btn' onClick={props.sendMail}>Enviar Correo</button>
                                </Can>
                                <button className='gray-btn' style={{position: 'absolute', right: 50}} onClick={props.onExtra}>Logs</button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </Modal>
    )
})


export function Resumen(props){
    return(
    <div className="footer-verificacion">
        <div className="table-footer">
            <table>
              <tr>
                <td className="registro-verificacion-footer" >Resumen de desempeño</td>
                <td className="registro-verificacion-footer" >
                    <table>
                        <tr>
                            <td>
                                Puntaje Alcanzado
                            </td>
                            <td>
                                N
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Puntaje Máximo a obtener
                            </td>
                            <td>
                                N
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Porcentaje de cumplumiento
                            </td>
                            <td>
                                N
                            </td>
                        </tr>
                    </table>
                </td>
              </tr>
            </table>
        </div>
    </div>
    )
}

export function Titulo(props){
    return(
        <>
            <div className="tabla-subtitulo">
                {props.titulo}
            </div>
        </>
    )
}


export function TablaCriterio(props){
    return(
        <>
            <div className="tabla-verificacion">
                <table id={props.id}>
                    <tr>
                        <th rowspan="2">Criterio</th>
                        <th colspan={props.semanas.length}>Semana de trabajo</th>
                        <th rowspan="2">Total General</th>
                    </tr>
                    <tr>
                        {
                            props.semanas.map(semana=>(
                                <th className="sub-headers-lista">{semana}</th>
                            ))
                        }
                    </tr>
                    {
                        props.criterios.map(criterio=>(
                            
                                criterio.criterio==='Cierre de la asignatura' || criterio.criterio==='Pre-cierre de la asignatura' ?
                                (
                                    <tr>
                                        <td className="subtitulo-encuadre" colspan={props.semanas.length+2}>{criterio.criterio}</td>
                                    </tr>
                                ):(
                                    <tr className={props.totalPuntosEsperandos ? 'total-puntos':null}>
                                        <td>{criterio.criterio}</td>
                                        {
                                            criterio.semanas.map(semana=>(
                                                <td className={semana==='N/A' ? 'semana-vacia':null }>{semana === 'N/A' ? '' : semana}</td>
                                            ))
                                        }
                                        <td>{criterio.total_general}</td>
                                    </tr>
                                )
                            
                        ))
                    } 
                </table>          
            </div>            
        </>
    )
}

export function TablaEvaluacion(props){
    return(
        <>
            <div className="evaluacion-final-titulo">
                {props.titulo}
            </div>
            <div className="tabla-evaluacion">
                <table id={props.id}>
                    <tr>
                        <th colspan={props.semanas.length}>Semana de trabajo</th>
                        <th rowspan="2">Total</th>
                    </tr>
                    <tr>
                        {
                            props.semanas.map(semana=>(
                                <th className="sub-headers-lista">{semana}</th>
                            ))
                        }
                    </tr>
                    {
                        props.categorias.map(categoria=>(
                            <tr className={props.totalPuntosEsperandos ? 'total-puntos':null}>
                                {
                                    categoria.semanas.map(semana=>(
                                        <td style={{backgroundColor:'#fbe9e7'}} >{semana}</td>
                                    ))
                                }
                                <td>{categoria.total}</td>
                            </tr>
                        ))
                    } 
                </table>          
            </div>           
        </>
    )
}
export function TablaValoracion(props){
    return(
        <>
            <div className="tabla-valoracion">
                <table>
                    <tr>
                        <th>Categoria</th>
                        <th colspan="2">Puntaje</th>
                        <th>Valoración</th>
                    </tr>
                        <tr style={{backgroundColor:'green'}}>
                        {
                            props.valoraciones[0].map(v=>(
                                <td>{v}</td>   
                            ))
                        }
                            <td rowspan="2">No requiere apoyo</td>
                        </tr>
                        <tr style={{backgroundColor:'green'}}>
                        {
                            props.valoraciones[1].map(v=>(
                                <td>{v}</td>   
                            ))
                        }
                        </tr>
                        <tr style={{backgroundColor:'yellow'}}>
                        {
                            props.valoraciones[2].map(v=>(
                                <td>{v}</td>   
                            ))
                        }
                            <td rowspan="2">Requiere apoyo</td>
                        </tr>
                        <tr style={{backgroundColor:'yellow'}}>
                        {
                            props.valoraciones[3].map(v=>(
                                <td>{v}</td>   
                            ))
                        }

                        </tr>
                        <tr style={{backgroundColor:'red'}}>
                        {
                            props.valoraciones[4].map(v=>(
                                <td>{v}</td>   
                            ))
                        }
                            <td>Seguimiento inmediato</td>
                        </tr>                        
                   
                </table>          
            </div>             
        </>
    )
}