import React, { useState, useEffect, useContext } from 'react';
import {
  Route,
  useHistory
} from 'react-router-dom';
import LoginPage from './Vistas/LoginPage';
import Header from './Utils/Visuales/Header';
import Menu from './Utils/Menu';
import Dashboard from './Vistas/Dashboard';
import Usuarios from './Vistas/Usuarios';
import Docentes from './Vistas/Docentes';
import Criterios from './Vistas/Criterios'
import Envio from './Vistas/Envio'
import Coordinadores from './Vistas/Coordinadores'
import Calendario from './Vistas/Calendario';
import './App.css';
import { postLogin, getRequest } from './Utils/Funciones/requester';
import { ability, setPermisos } from './Utils/Funciones/ability';
import Historial from './Vistas/Historial';
import ReportesLista from './Vistas/Reportes/ReportesLista';
import ReportesConcentrado from './Vistas/Reportes/ReportesConcentrado';
import ReportesCriterios from './Vistas/Reportes/ReportesCriterios';
import ReportesAsignacion from './Vistas/Reportes/ReportesAsignacion';
import {ListaVerificacionProvider} from './Utils/Providers/ListaVerificacionProvider';
import {ConcentradoProvider} from './Utils/Providers/ConcentradoProvider';
import {CursoProvider} from './Utils/Providers/CursoProvider';
import NivelesContext from './Utils/Providers/NivelesProvider';
import { useSnackbar } from 'notistack';

export default function App(){
  const [username, setUsername] = useState('')
  const [usernameError, setUsernameError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [logged, setLogged] = useState(false)
  const [logging, setLogging] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [nombre, setNombre] = useState('Usuario')
  const [userRol, setUserRol] = useState('Rol')
  const [image, setImage] = useState('')
  let history = useHistory();

  const context = useContext(NivelesContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  async function login(){
    setErrorMsg('')
    let valido = true;
    if(username === ''){
      valido = false;
      setUsernameError(true)
    } else {
      setUsernameError(false)
    }
    if(password === ''){
      valido = false
      setPasswordError(true)
    } else {
      setPasswordError(false)
    }

    if(valido){
      setLogging(true)
      let response = await postLogin('/login', {
        user: username,
        passwd: password
      })
      if(response.s === 'OK'){
        localStorage.setItem('token_tv', response.d.token)
        ability.update(setPermisos(response.d.permisos))
        setNombre(response.d.nombre)
        setUserRol(response.d.rol)
        setImage(response.d.url_img)
        let responseNiveles = await getRequest('/nivel');
        if(responseNiveles.s === 'OK'){
          if(responseNiveles.d.length > 0){
            let data = []
            responseNiveles.d.forEach(nivel => {
              let totalSemanas = 1
              nivel.calendarios.forEach(modulo => {
                if(modulo.total_semanas > totalSemanas){
                  totalSemanas = modulo.total_semanas;
                }
              })
              data.push({
                label: nivel.nombre,
                value: nivel.nombre,
                semanas: totalSemanas
              })
            })
            context.actions.setNiveles(data);
          } else {
            enqueueSnackbar(responseNiveles.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            context.actions.setNiveles([]);
          }
        } else {
          enqueueSnackbar(response.m, {
            variant: 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
          });
        }
        setLogging(false)
        setLogged(true)
        history.push('/dashboard')
      } else {
        setLogging(false)
        setErrorMsg(response.m)
      }
    }
  }

  useEffect(() => {
    async function checkLogin(){
      let token = localStorage.getItem('token_tv')
      if(token){
        setLogging(true)
        let response = await getRequest('/login/detalle')
        if(response.s === 'OK'){
          let responseNiveles = await getRequest('/nivel');
          if(responseNiveles.s === 'OK'){
            if(responseNiveles.d.length > 0){
              let data = []
              responseNiveles.d.forEach(nivel => {
                let totalSemanas = 1
                nivel.calendarios.forEach(modulo => {
                  if(modulo.total_semanas > totalSemanas){
                    totalSemanas = modulo.total_semanas;
                  }
                })
                data.push({
                  label: nivel.nombre,
                  value: nivel.nombre,
                  semanas: totalSemanas
                })
              })
              context.actions.setNiveles(data);
            } else {
              enqueueSnackbar(responseNiveles.m, {
                  variant: 'error',
                  anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right'
                  }
              });
              context.actions.setNiveles([]);
            }
          } else {
            enqueueSnackbar(response.m, {
              variant: 'error',
              anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
              }
            });
          }
          ability.update(setPermisos(response.d.permisos))
          setLogging(false)
          setLogged(true)
          setNombre(response.d.nombre)
          setUserRol(response.d.rol)
          setImage(response.d.url_img)
          history.push('/dashboard')
        } else {
          setLogging(false)
        }
      }
    }
    checkLogin()
  }, [])

  return(
    <>
      {
        logged ?
        <ListaVerificacionProvider>
          <ConcentradoProvider>
            <CursoProvider>
              <Header username={nombre} rol={userRol} image={image} />
              <div className='container-main'>
              <Menu />
              <Route path='/dashboard'>
                <Dashboard />
              </Route>
              <Route path='/usuarios'>
                <Usuarios />
              </Route>
              <Route path='/docentes'>
                <Docentes />
              </Route>
              <Route path='/coordinadores'>
                <Coordinadores />
              </Route>
              <Route path='/calendario'>
                <Calendario />
              </Route>
              <Route path='/criterios'>
                <Criterios />
              </Route>
              <Route path='/historial'>
                <Historial />
              </Route>
              <Route path='/envio'>
                <Envio />
              </Route>
              <Route path='/reportes/lista'>
                <ReportesLista />
              </Route>
              <Route path='/reportes/concentrado'>
                <ReportesConcentrado />
              </Route>
              <Route path='/reportes/criterios'>
                <ReportesCriterios />
              </Route>
              <Route path='/reportes/curso'>
                <ReportesAsignacion />
              </Route>
            </div>
            </CursoProvider>
          </ ConcentradoProvider>
        </ ListaVerificacionProvider> :
        <LoginPage login={login} user={username} error={errorMsg} userError={usernameError} passError={passwordError} setUser={setUsername} setPass={setPassword} loading={logging} />
      }
    </>
  )
}
