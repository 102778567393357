import React from 'react'
import Modal from '@material-ui/core/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import './style.css'

export default function NewModal(props){
    return(
        <Modal open={props.open} onClose={props.handleClose} style={{zIndex: 9999999}} disableBackdropClick>
            <div className='NewModal'>
                <CloseIcon onClick={props.handleClose} />
                <h3>{props.title}</h3>
                <div className='NewModal-container' style={{height: props.height}}>
                    {
                        props.loading ?
                        <div style={{textAlign: 'center', paddingTop: 30}}>
                            <CircularProgress size={50} />
                        </div> :
                        <>
                            {props.children}
                            <div className='NewModal-footer'>
                                {
                                    props.canDelete ?
                                    <button onClick={props.canDelete} className='delete-button'>Eliminar</button> : null
                                }
                                <button className='gray-btn' onClick={props.handleClose}>Cancelar</button>
                                {

                                    props.noSaveBtn ?
                                    null
                                    : <button className='black-btn' onClick={props.handleSubmit}>Aceptar</button>

                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </Modal>
    )
}