import React from 'react';
import PageTitle from '../PageTitle';
import './index.css';
import Footer from '../Footer'

export default function Contenedor(props){
    return (
        <main>
            <PageTitle title={props.title} />
            <div className='contenedor'>
                {props.children}
            </div>
            <Footer version='0.0.1' />
        </main>
    )
}