import React from 'react'
import './style.css'

export default function Footer(props){
    return(
        <footer>
            <span>V{props.version}</span>
            <span>Powered by Jalisoft S de RL de CV</span>
        </footer>
    )
}