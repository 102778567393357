import React, { useState, useEffect, useContext } from 'react'
import Contenedor from '../../Utils/Visuales/Contenedor'
import SearchInput from '../../Utils/Visuales/SearchInput'
import MasivaButton from '../../Utils/Botones/MasivaButton'
import DataSubTable from '../../Utils/DataSubTables'
import { getRequest, postRequest } from '../../Utils/Funciones/requester'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import esLocale from "date-fns/locale/es"
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import Select from 'react-select'
import ModalWithoutFooter from '../../Utils/Visuales/ModalWithoutFooter'
import downloadJS from 'downloadjs'
import ConcentradoContext from '../../Utils/Providers/ConcentradoProvider'
import { useSnackbar } from 'notistack';
import NivelesContext from '../../Utils/Providers/NivelesProvider';
import BlackButton from '../../Utils/Botones/BlackButton';

export default function ReportesConcentrado(props){
    const context = useContext(ConcentradoContext)
    const headers = [
        {
            name: 'Coord. Titular',
            col: 0
        },
        {
            name: 'Coord. Seguimiento',
            col: 1
        },
        {
            name: 'Clave',
            col: 2
        },
        {
            name: 'Docente',
            col: 3
        },
        {
            name: 'Clave del Curso',
            col: 4
        },
        {
            name: 'Curso',
            col: 5
        }
    ]
    const [dataFinal, setDataFinal] = useState([])
    const [dataReal, setDataReal] = useState([])
    const [dataToFilterModulo,setDataToFilterModulo] = useState([]);
    const [loading, setLoading] = useState(false)
    const [semanas,setSemanas]=useState([])
    const subHeaders=['Alcance','Desempeño']
    const cycles = [
        {
            value: '01',
            label: '01'
        },
        {
            value: '02',
            label: '02'
        },
        {
            value: '03',
            label: '03'
        }
    ]
    const [year, setYear] = useState(new Date())
    const [cycle, setCycle] = useState(context.state.cycle ? {
        value: context.state.cycle,
        label: parseInt(context.state.cycle)
    } : {
        value: '01',
        label: '01'
    })

    const [modulos,setModulos] = useState([{value: 'Todos',label: 'Todos'}])
    const [modulo,setModulo] = useState(context.state.modulo ? context.state.modulo : {label: 'Todos', value: 'Todos'})    
    const [levels, setLevels] = useState(useContext(NivelesContext).state.levels);
    const [level, setLevel] = useState(context.state.level ? {
        value: context.state.level,
        label: context.state.level
    } : levels[0]);
    const [docentes, setDocentes] = useState([])
    const [docente, setDocente] = useState(context.state.docente ? {
        value: context.state.claveDocente,
        label: context.state.docente
    } : {
        value: 'todos',
        label: 'Todos'
    })

    const [modalDownload, setModalDownload] = useState(false)
    const [modalDownloadLoading, setModalDownloadLoading] = useState(false)
    const [refreshDocente, setRefreshDocente] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        context.actions.setState(null, null, null, null)

        fetchData()
    }, [])

    async function fetchData(){
        try {
            setLoading(true)
            let json = {
                year: moment(year).format('YY'),
                nivel: level.value,
                ciclo: cycle.value
            }
            let response = await postRequest('/reporte/concentrado', json)
            if(response.s === 'OK'){
                let data = []
                let dataDocentes = [{value: 'todos', label: 'Todos'}]
                let dataModulos = [{
                    value:'Todos',
                    label:'Todos'
                }]
                let myModulosReg=[]
                if(response.d){
                    let dataSemanas = []
                    for(let i = 0; i < response.d[0].numero_semanas; i++){
                        dataSemanas.push(`Semana ${i+1}`)
                    }
                    if(dataSemanas.length > 0){
                        setSemanas(dataSemanas)
                    } else {
                        setSemanas(['Semana 0'])
                    }
                    
                    response.d.forEach(row => {
                        if(myModulosReg.includes(row.modulo)){

                        }else{
                            myModulosReg.push(row.modulo)
                            dataModulos.push({
                                value:row.modulo,
                                label:row.modulo
                            })
                        }
                        let semanasAux = row.resultados
                        semanasAux.sort((a, b) => {
                            return a.semana - b.semana
                        })
                        data.push([
                            row.coordinador_titular,
                            row.coordinador_seguimiento,
                            row.clave_docente,
                            row.nombre_docente,
                            row.clave_curso,
                            row.nombre_curso,
                            semanasAux.map(semana => (
                                {
                                    alcance: semana.avance,
                                    "desempeño": semana['desempeño'],
                                    "estatus":row.status_curso
                                }
                            )),
                            row.modulo
                        ])
                        dataDocentes.push({
                            value: row.clave_docente,
                            label: row.nombre_docente
                        })
                    })
                } else {
                    setSemanas(['Semana 1'])
                }
                setLoading(false)
                setDataToFilterModulo(data)
                let text = ""
                if(modulo.value==="A"){
                    text="-531"
                }else if(modulo.value==="B"){
                    text="-532"
                }
                text = text.toLowerCase()
                let result = data.filter(row => {
                    if(row[4].toLowerCase().includes(text)) return true
                    return false
                })
                setDataReal(result)
                setDataFinal(result)
                setModulos(dataModulos)

                dataDocentes = Array.from(new Set(dataDocentes.map(s => s.value)))
                    .map(value => {
                        return {
                            value: value,
                            label: dataDocentes.find(s => s.value === value).label
                        }
                    })
                setDocentes(dataDocentes)
                setRefreshDocente(!refreshDocente)
            } else {
                enqueueSnackbar(response.m, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                setDataFinal([]);
                setDataReal([]);
                setLoading(false);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        let auxData = []
        if (docente.value === 'todos'){
            auxData = dataReal
        } else {
            auxData = dataReal.filter(row => row[2] === docente.value)
        }
        setDataFinal(auxData)
    }, [docente, refreshDocente])

    function search(text){
        text = String(text).toLowerCase()
        let result = dataReal.filter(row => {
            if(row[0].toLowerCase().includes(text)) return true
            else if(row[1].toLowerCase().includes(text)) return true
            else if(row[2].toLowerCase().includes(text)) return true
            else if(row[3].toLowerCase().includes(text)) return true
            else if(String(row[4]).toLowerCase().includes(text)) return true
            else if(String(row[5]).toLowerCase().includes(text)) return true
            return false
        })
        setDataFinal(result)
    }

    async function downloadReport(){
        setModalDownload(true)
        setModalDownloadLoading(true)
    
        let json = {
            year: moment(year).format('YY'),
            ciclo: cycle.value,
            nivel: level.value
        }

        let response = await postRequest('/reporte/concentrado/excel', json)

        if(response.s === 'OK'){
            downloadJS(response.d)
            setModalDownload(false)
        } else {
            enqueueSnackbar('Ha ocurrido un error en la descarga.', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setModalDownload(false);
        }
    }
    function handleChangeModulo(e){
        let text = ""
        if(e.value==="Todos"){
            text=""
        }else{
            text=e.value
        }
        text = text.toLowerCase()
        let result = dataToFilterModulo.filter(row => {
            if(row[7].toLowerCase().includes(text)) return true
            return false
        })
        setModulo(e)
        setDataFinal(result)
        setDataReal(result)
    }
    return(
        <Contenedor title='Reporte de Concentrado'>
            <div className='opciones-bar opciones-bar-reporte'>
                <div className='tab-filtros' style={{display: 'block'}}>
                    <div>
                        <MasivaButton onClick={downloadReport} disabled={dataReal.length === 0}>Descargar</MasivaButton>
                    </div>
                    <div>
                        <p>Nivel:</p>
                        <Select 
                            options={levels}
                            className='select-weeks'
                            classNamePrefix='select-search'
                            value={level}
                            onChange={setLevel}
                        />
                    </div>
                    <div>
                        <p>Año:</p>
                        <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                            <DatePicker views={["year"]} value={year} onChange={setYear} className="date-year" />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div>
                        <p>Ciclo:</p>
                        <Select 
                            options={cycles}
                            className='select-weeks'
                            classNamePrefix='select-search'
                            value={cycle}
                            onChange={setCycle}
                        />
                    </div>
                    <div>
                        <p>Docente:</p>
                        <Select 
                            options={docentes}
                            className='select-docente-largo'
                            classNamePrefix='select-search'
                            value={docente}
                            onChange={setDocente}
                        />
                    </div>
                    <div>
                        <p>Módulo:</p>
                        <Select
                            options={modulos}
                            className='select-docente-largo'
                            classNamePrefix='select-search'
                            value={modulo}
                            onChange={handleChangeModulo}
                        />
                    </div>
                    <div>
                        <BlackButton style={{marginLeft: '20px'}} onClick={() => fetchData()}>Buscar</BlackButton>
                    </div>
                    <div style={{marginLeft: 5, position: 'relative', paddingTop: '15px', float: 'right'}}>
                        <SearchInput search={search} />
                    </div>
                </div>
            </div>
            <div style={{height: 'calc(100% - 120px)'}}>
                <DataSubTable
                    headers={headers}
                    data={dataFinal}
                    loading={loading}
                    paginate
                    subHeaders={subHeaders}
                    semanas={semanas}
                />
            </div>
            <ModalWithoutFooter open={modalDownload} title='Reporte Concentrado' loading={modalDownloadLoading} height={150}>

            </ModalWithoutFooter>
        </Contenedor>
    )
}